import belette from '../../../assets/beletteMarron256.png'
import beletteBleu from '../../../assets/beletteBleu256.png'
import BadgeVoyageur from '../../../assets/badges/BadgeVoyageur.png'
import BadgeExplorateur from '../../../assets/badges/BadgeExplorateur.png'
import BadgeGlobeTrotteur from '../../../assets/badges/BadgeGlobe.png'
import BadgePionnier from '../../../assets/badges/BadgePionnier.png'

const UserPresentation = ({ userLevel = 0, userPseudo }) => {
	// Définir les niveaux, leurs titres et leurs badges
	const levels = [
		{
			min: 0,
			max: 0.5,
			title: 'Curieux',
			badge: belette,
			info: "Bienvenue dans l'aventure WeaselMap ! Partagez votre premier lieu pour commencer."
		},
		{
			min: 1,
			max: 3.5,
			title: 'Voyageur',
			badge: BadgeVoyageur,
			info: 'Vous avez partagé votre premier lieu ! Continuez à enrichir la carte pour gagner de nouveaux badges.'
		},
		{
			min: 4,
			max: 7.5,
			title: 'Explorateur',
			badge: BadgeExplorateur,
			info: 'Avec 4 lieux partagés, vous êtes sur la bonne voie. Continuez à explorer !'
		},
		{
			min: 8,
			max: 12.5,
			title: 'Globe-Trotteur',
			badge: BadgeGlobeTrotteur,
			info: 'Impressionnant ! 8 lieux partagés. Votre contribution inspire la communauté.'
		},
		{
			min: 13,
			max: 100000,
			title: 'Pionnier WeaselMap',
			badge: BadgePionnier,
			info: 'Félicitations, vous êtes un véritable pionnier et défenseur du patrimoine !'
		}
	]

	// Trouver le niveau actuel de l'utilisateur
	const currentLevel = levels.find(
		(level) => userLevel >= level.min && userLevel < level.max
	)

	return (
		<div className="userPresentation">
			<div className="userPresentation__name">
				<span id="presentationName">Bonjour {userPseudo},</span> <br />{' '}
				{userLevel > 1 ? (
					<div>
						<div id="presentationHead">
							Vous avez gagné le titre :
							<br />
							<span id="presentationTitle">
								{currentLevel?.title || 'Inconnu'}
							</span>{' '}
							(niveau {userLevel})
							<br /> Félicitations 🎉
						</div>
						{userLevel >= 8 ? (
							<div id="presentationMessage">
								Vous avez débloqué tous les badges, vous êtes un
								modèle pour la communauté et un expert du
								territoire.
							</div>
						) : (
							<div id="presentationMessage">
								<span>{currentLevel?.info}</span>
							</div>
						)}
					</div>
				) : (
					<div>
						<div id="presentationMessageCurious">
							Vous êtes{' '}
							<span id="presentationTitle">
								{currentLevel?.title || 'Inconnu'}
							</span>{' '}
							de découvrir de nouveaux endroits ? La communauté
							aussi, partagez vos lieux préférés et gagnez des
							badges !
						</div>
					</div>
				)}
			</div>
			<div className="userPresentation__badges">
				{/* Afficher le badge principal du niveau en cours */}
				<div className="userPresentation__mainBadge">
					<img
						src={currentLevel?.badge || beletteBleu}
						alt={`Badge niveau ${userLevel}`}
						title={currentLevel?.title}
					/>
				</div>
				<div className="userPresentation__lvlBadges">
					{/* Afficher les badges des niveaux */}
					{levels.map((level, index) => (
						<div
							key={index}
							className={`userPresentation__badge userPresentation__badge${index}`}
						>
							<img
								src={
									userLevel >= level.min
										? level.badge // Affiche le badge débloqué
										: beletteBleu // Affiche la belette bleue si le niveau n'est pas débloqué
								}
								alt={`Badge ${level.title}`}
							/>
							<span>{level.title}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default UserPresentation
