import React, { useState } from 'react'
import { geocodeCity } from '../../../services/geolocation'

const CitySearch = ({ onCitySelect }) => {
	const [city, setCity] = useState('')
	const [error, setError] = useState(null)

	const handleSearch = async (e) => {
		e.preventDefault()

		// Vérifier si le champ est vide
		if (!city.trim()) {
			setError('Veuillez saisir une ville avant de lancer la recherche.')
			return
		}

		try {
			// Appel à la fonction de géocodage pour obtenir les coordonnées de la ville
			const coordinates = await geocodeCity(city)

			// Si la ville est trouvée, passez les coordonnées au parent
			if (coordinates) {
				onCitySelect(coordinates, city)
				setError(null) // Réinitialiser l'erreur en cas de succès
			} else {
				setError('Ville non trouvée. Veuillez essayer une autre.')
			}
		} catch (err) {
			console.error('Erreur lors du géocodage de la ville:', err)
			setError('Une erreur est survenue lors de la recherche.')
		}
	}

	return (
		<div className="citySearch">
			<form onSubmit={handleSearch}>
				<input
					type="text"
					placeholder="Rechercher une ville"
					value={city}
					onChange={(e) => setCity(e.target.value)}
				/>
				<button type="submit">
					<span
						className="material-symbols-outlined"
						title="rechercher"
					>
						search
					</span>
				</button>
			</form>
			{error && <p className="error">{error}</p>}
		</div>
	)
}

export default CitySearch
