import PropTypes from 'prop-types'

///////////////Components///////////////

const Rating = ({ data, variant }) => {
	const range = [1, 2, 3, 4, 5]
	const rating = data.average
	const numberRating = data.ratingsCount

	// Fonction pour formater le rating : sans virgule si c'est un entier, sinon 1 chiffre après la virgule
	const formattedRating = Number.isInteger(rating)
		? rating
		: rating.toFixed(1)

	// Si le nombre de notes est 0, ne rien afficher
	if (numberRating === 0 || numberRating === null) {
		return <div className="rating__message">Pas encore de note</div>
	}

	return (
		<div className="rating">
			{/* Affichage des étoiles */}
			{variant === 'menu1' && (
				<>
					<div className="rating__star rating__star-full">
						{rating >= 3 ? '★' : '☆'}
					</div>
					<span className="rating__note">{formattedRating}/5</span>
					{/* Affichage du nombre de notes */}
					<span className="rating__nbrNote">
						({numberRating} {numberRating > 1 ? 'notes' : 'note'})
					</span>
				</>
			)}

			{/* Affichage des étoiles dans le variant 'menu2' */}
			{variant === 'menu2' && (
				<>
					<div className="rating__star">
						{range.map((rangeElement, index) =>
							rating >= rangeElement ? (
								<span
									className="rating__star-full"
									key={`full-${index}`}
								>
									★
								</span>
							) : (
								<span
									className="rating__star-empty"
									key={`empty-${index}`}
								>
									☆
								</span>
							)
						)}
					</div>
					{/* Affichage du nombre de notes */}
					<span className="rating__nbrNote">
						({numberRating} {numberRating > 1 ? 'notes' : 'note'})
					</span>
				</>
			)}

			{variant === 'evaluation' && (
				<>
					<div className="rating__evaluation rating__star rating__star-full">
						{rating >= 3 ? '★' : '☆'}
					</div>
					<span className="rating__note">{formattedRating}/5</span>
				</>
			)}
		</div>
	)
}

Rating.propTypes = {
	data: PropTypes.shape({
		average: PropTypes.number.isRequired,
		ratingsCount: PropTypes.number.isRequired
	}).isRequired,
	variant: PropTypes.string.isRequired
}

export default Rating
