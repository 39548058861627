import { useState, useEffect } from 'react'
import {
	doc,
	getDoc,
	setDoc,
	updateDoc,
	getDocs,
	collection,
	query,
	where,
	orderBy
} from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'

//Components
import CreateAccountButton from '../Btn-CreateAccount'
import Rating from '../Rating'
import Modal from '../Modal'
import ReportButton from '../Btn-Report/BtnReport'

//Objets
import belette from '../../../assets/beletteMarron256.png'
import BadgeVoyageur from '../../../assets/badges/BadgeVoyageur.png'
import BadgeExplorateur from '../../../assets/badges/BadgeExplorateur.png'
import BadgeGlobeTrotteur from '../../../assets/badges/BadgeGlobe.png'
import BadgePionnier from '../../../assets/badges/BadgePionnier.png'

const Evaluation = ({
	placeId,
	userStatus,
	userEmail,
	isEmailVerified,
	isAnonymous, // Passé comme prop
	userId, // Passé comme prop
	onRatingChange
}) => {
	const [userRating, setUserRating] = useState(null) // Note de l'utilisateur
	const [hoverRating, setHoverRating] = useState(null) // Note temporaire lors du survol
	const [hasRated, setHasRated] = useState(false) // Vérifie si l'utilisateur a déjà noté
	const [comment, setComment] = useState('') // Commentaire de l'utilisateur
	const [hasCommented, setHasCommented] = useState(false) // Si l'utilisateur a déjà commenté
	const [comments, setComments] = useState([]) // Liste des commentaires
	const [isEditing, setIsEditing] = useState(false) // Gérer l'édition du commentaire
	const [allComments, setAllComments] = useState([]) // Liste de tous les commentaires pour la modale
	const [showModal, setShowModal] = useState(false) // Gérer l'affichage de la modale
	const [infoMessage, setInfoMessage] = useState('') // Message d'information
	const [userLevels, setUserLevels] = useState({}) // Stocke les niveaux des utilisateurs

	// Fonction utilitaire pour formater la date en format lisible
	const formatDate = (timestamp) => {
		const options = { year: 'numeric', month: 'short', day: 'numeric' }
		if (timestamp && timestamp.seconds) {
			const date = new Date(timestamp.seconds * 1000)
			return date.toLocaleDateString('fr-FR', options)
		} else if (timestamp instanceof Date) {
			return timestamp.toLocaleDateString('fr-FR', options)
		} else {
			return new Date(timestamp).toLocaleDateString('fr-FR', options)
		}
	}

	// Récupération des évaluations et des commentaires lorsque le composant se charge
	useEffect(() => {
		const fetchData = async () => {
			if (userId && placeId && !isAnonymous) {
				// Récupérer les données en localStorage en premier lieu
				const localData = localStorage.getItem(
					`userRating_${placeId}_${userId}`
				)
				if (localData) {
					const parsedData = JSON.parse(localData)
					setUserRating(parsedData.rating)
					setComment(parsedData.comment || '')
					setHasRated(true)
					setHasCommented(!!parsedData.comment)
				} else {
					// Si aucune donnée en localStorage, appeler Firestore pour récupérer la note et le commentaire
					const userRatingDoc = await getDoc(
						doc(firestore, `ratings/${placeId}_${userId}`)
					)
					if (userRatingDoc.exists()) {
						const data = userRatingDoc.data()
						setUserRating(data.rating)
						setComment(data.comment || '')
						setHasRated(true)
						setHasCommented(!!data.comment)

						// Enregistrer les données dans le localStorage
						localStorage.setItem(
							`userRating_${placeId}_${userId}`,
							JSON.stringify(data)
						)
					}
				}
			}

			// Récupérer tous les commentaires en une seule requête
			const commentsQuery = query(
				collection(firestore, 'ratings'),
				where('placeId', '==', placeId),
				where('comment', '>', ''),
				orderBy('timestamp', 'desc')
			)
			const querySnapshot = await getDocs(commentsQuery)
			const allCommentsData = querySnapshot.docs.map((doc) => doc.data())

			// Mettre à jour les états en utilisant les données récupérées
			setAllComments(allCommentsData) // Stocker tous les commentaires pour utilisation future
			setComments(allCommentsData.slice(0, 5)) // Afficher les 5 premiers par défaut
		}

		fetchData()
	}, [placeId, userId, isAnonymous])

	// Définir les badges pour chaque niveau
	const levels = [
		{ min: 0, max: 0.5, badge: belette },
		{ min: 1, max: 2.5, badge: BadgeVoyageur },
		{ min: 3, max: 4.5, badge: BadgeExplorateur },
		{ min: 5, max: 7.5, badge: BadgeGlobeTrotteur },
		{ min: 8, max: 100000, badge: BadgePionnier }
	]

	// Récupérer les niveaux des utilisateurs
	useEffect(() => {
		const fetchUserLevels = async () => {
			const levelsData = {}

			for (const comment of comments) {
				if (comment.userId && !levelsData[comment.userId]) {
					try {
						const userDoc = await getDoc(
							doc(firestore, 'users', comment.userId)
						)
						if (userDoc.exists()) {
							levelsData[comment.userId] =
								userDoc.data().level || 0
						} else {
							levelsData[comment.userId] = null // Aucun niveau trouvé
						}
					} catch (error) {
						console.error(
							`Erreur lors de la récupération du niveau de l'utilisateur ${comment.userId} :`,
							error
						)
					}
				}
			}

			setUserLevels(levelsData)
		}

		if (comments.length > 0) {
			fetchUserLevels()
		}
	}, [comments])

	// Trouver le badge correspondant au niveau
	const getBadgeByLevel = (level) => {
		const currentLevel = levels.find(
			(levelObj) => level >= levelObj.min && level < levelObj.max
		)
		return currentLevel?.badge || belette
	}

	// Fonction pour afficher tous les commentaires dans une modale
	const fetchAllComments = () => {
		setShowModal(true)
	}

	const updateAverageRating = async (placeId) => {
		const ratingsSnapshot = await getDocs(
			query(
				collection(firestore, 'ratings'),
				where('placeId', '==', placeId)
			)
		)

		let totalRatings = 0
		let ratingsSum = 0

		ratingsSnapshot.forEach((doc) => {
			ratingsSum += doc.data().rating
			totalRatings++
		})

		const averageRating = totalRatings > 0 ? ratingsSum / totalRatings : 0

		await updateDoc(doc(firestore, `heritage/${placeId}`), {
			'properties.rating.average': averageRating,
			'properties.rating.ratingsCount': totalRatings
		})
	}

	const handleRatingClick = async (rating) => {
		if (userId && placeId && !isAnonymous) {
			const userDoc = await getDoc(doc(firestore, `users/${userId}`))
			const pseudo = userDoc.exists() ? userDoc.data().pseudo : 'Anonyme'

			const ratingRef = doc(firestore, `ratings/${placeId}_${userId}`)
			const newRatingData = {
				rating,
				userId,
				pseudo,
				placeId,
				timestamp: new Date()
			}

			if (hasRated) {
				await updateDoc(ratingRef, newRatingData)
			} else {
				await setDoc(ratingRef, newRatingData)
				setHasRated(true)
			}

			setUserRating(rating)
			if (onRatingChange) onRatingChange(rating)

			// Enregistrer les données dans le localStorage
			localStorage.setItem(
				`userRating_${placeId}_${userId}`,
				JSON.stringify(newRatingData)
			)

			await updateAverageRating(placeId)
		}
	}

	const handleCommentSubmit = async () => {
		// Réinitialiser le message avant chaque tentative de soumission
		setInfoMessage('')

		if (!hasRated) {
			setInfoMessage(
				'Merci de mettre une note avant de publier un commentaire.'
			)
			return
		}

		if (comment.length < 4) {
			setInfoMessage('Le commentaire doit faire au moins 4 caractères')
			return
		}

		if (userId && placeId && !isAnonymous) {
			const userDoc = await getDoc(doc(firestore, `users/${userId}`))
			const pseudo = userDoc.exists() ? userDoc.data().pseudo : 'Anonyme'

			const ratingRef = doc(firestore, `ratings/${placeId}_${userId}`)
			await updateDoc(ratingRef, {
				comment,
				pseudo,
				timestamp: new Date()
			})
			setHasCommented(true)
			setIsEditing(false)

			const newComment = {
				comment,
				userId,
				pseudo,
				rating: userRating,
				timestamp: new Date()
			}

			const updatedComments = comments.map((c) =>
				c.userId === userId ? newComment : c
			)
			setComments(updatedComments)

			// Enregistrer les commentaires dans le localStorage
			localStorage.setItem(
				`userRating_${placeId}_${userId}`,
				JSON.stringify({ ...newComment, rating: userRating })
			)
			localStorage.setItem(
				`comments_${placeId}`,
				JSON.stringify(updatedComments)
			)
		}
	}

	const handleEditComment = () => {
		setIsEditing(true)
		setInfoMessage('') // Effacer le message lorsque l'utilisateur modifie le commentaire
	}

	// Si l'utilisateur est anonyme
	if (isAnonymous) {
		return (
			<div className="evaluation evaluation__anonymous">
				<p>
					Vous devez être connecté(e) pour évaluer et laisser un
					commentaire.
				</p>
				<CreateAccountButton className="evaluation__createAccountButton" />
				{/* Affichage des commentaires récents */}
				<div className="evaluation__usersComments">
					<h3>Commentaires récents</h3>

					{comments.length === 0 && (
						<p className="comment">Pas encore de commentaire </p>
					)}
					{comments.map((comment, index) => (
						<div key={index} className="evaluation__userComment">
							<div className="userComment">
								<div className="userComment__info">
									<div className="userComment__badge">
										{/* Affiche le badge si un niveau est trouvé */}
										{userLevels[comment.userId] !==
											undefined &&
											userLevels[comment.userId] > 0 && (
												<img
													src={getBadgeByLevel(
														userLevels[
															comment.userId
														]
													)}
													alt="Badge utilisateur"
													title={`Niveau: ${
														userLevels[
															comment.userId
														]
													}`}
												/>
											)}
									</div>
									<strong>
										{comment.pseudo || 'Anonyme'}
									</strong>
									<Rating
										data={{
											average: comment.rating,
											ratingsCount: 1
										}}
										variant="evaluation"
									/>
								</div>
								<div className="comment-date-report">
									({formatDate(comment.timestamp)})
									<ReportButton
										commentId={`${placeId}_${
											comment.userId || index
										}`}
										placeId={placeId}
										elementName={`le commentaire de ${comment.pseudo}`}
										userEmail={userEmail || ''}
										isEmailVerified={isEmailVerified}
										userStatus={userStatus}
										type={'comment'}
										className={'-comment'}
									/>
								</div>
							</div>
							<p className="comment">{comment.comment}</p>
						</div>
					))}
					{allComments.length > 5 && (
						<button onClick={fetchAllComments} title="Voir plus">
							Voir plus de commentaires
						</button>
					)}
				</div>
				<Modal
					isOpen={showModal}
					onClose={() => setShowModal(false)}
					className={'modalComment'}
				>
					<div className="evaluation__usersComments">
						<h3>Tous les commentaires</h3>
						{allComments.map((comment, index) => (
							<div
								key={index}
								className="evaluation__userComment"
							>
								<div className="userComment">
									<div className="userComment__info">
										<div className="userComment__badge">
											{/* Affiche le badge si un niveau est trouvé */}
											{userLevels[comment.userId] !==
												undefined &&
												userLevels[comment.userId] >
													0 && (
													<img
														src={getBadgeByLevel(
															userLevels[
																comment.userId
															]
														)}
														alt="Badge utilisateur"
														title={`Niveau: ${
															userLevels[
																comment.userId
															]
														}`}
													/>
												)}
										</div>
										<strong>
											{comment.pseudo || 'Anonyme'}
										</strong>
										<Rating
											data={{
												average: comment.rating,
												ratingsCount: 1
											}}
											variant="evaluation"
										/>
									</div>
									<div className="comment-date-report">
										({formatDate(comment.timestamp)})
										<ReportButton
											commentId={`${placeId}_${
												comment.userId || index
											}`}
											placeId={placeId}
											elementName={`le commentaire de ${comment.pseudo}`}
											userEmail={userEmail || ''}
											isEmailVerified={isEmailVerified}
											userStatus={userStatus}
											type={'comment'}
											className={'-comment'}
										/>
									</div>
								</div>
								<p className="comment">{comment.comment}</p>
							</div>
						))}
					</div>
				</Modal>
			</div>
		)
	}

	return (
		<div
			className={`evaluation ${hasCommented ? 'evaluation-reverse' : ''}`}
		>
			<div className="evaluation__rating">
				<span>{hasRated ? 'Votre note : ' : 'Noter ce lieu : '}</span>
				<div className="evaluation__star">
					{[1, 2, 3, 4, 5].map((star) => (
						<Star
							key={star}
							filled={hoverRating >= star || userRating >= star}
							onMouseEnter={() => setHoverRating(star)}
							onMouseLeave={() => setHoverRating(null)}
							onClick={() => handleRatingClick(star)}
						/>
					))}
				</div>
			</div>

			<div className="evaluation__comment">
				{hasCommented && !isEditing ? (
					<>
						<p>Vous avez déjà laissé un commentaire.</p>
						<button
							onClick={handleEditComment}
							className="hasCommented"
							title="Modifier"
						>
							Modifier votre commentaire
						</button>
					</>
				) : (
					<>
						<textarea
							placeholder="Laissez un commentaire..."
							value={comment}
							onChange={(e) => {
								if (e.target.value.length <= 250) {
									setComment(e.target.value)
								}
							}}
						/>
						<span>{comment.length}/250 caractères</span>
						<button
							onClick={handleCommentSubmit}
							className={
								!hasRated || comment.length < 4
									? 'button-disabled'
									: 'commented'
							}
							title="Commenter"
						>
							Publier le commentaire
						</button>
					</>
				)}
				{infoMessage && <p className="infoMessage">{infoMessage}</p>}
			</div>

			<div className="evaluation__usersComments">
				<h3>Commentaires récents</h3>

				{comments.length === 0 && (
					<p className="comment">Pas encore de commentaire </p>
				)}
				{comments.map((comment, index) => (
					<div key={index} className="evaluation__userComment">
						<div className="userComment">
							<div className="userComment__info">
								<div className="userComment__badge">
									{/* Affiche le badge si un niveau est trouvé */}
									{userLevels[comment.userId] !== undefined &&
										userLevels[comment.userId] > 0 && (
											<img
												src={getBadgeByLevel(
													userLevels[comment.userId]
												)}
												alt="Badge utilisateur"
												title={`Niveau: ${
													userLevels[comment.userId]
												}`}
											/>
										)}
								</div>
								<strong>{comment.pseudo || 'Anonyme'}</strong>
								<Rating
									data={{
										average: comment.rating,
										ratingsCount: 1
									}}
									variant="evaluation"
								/>
							</div>
							<div className="comment-date-report">
								({formatDate(comment.timestamp)})
								<ReportButton
									commentId={`${placeId}_${
										comment.userId || index
									}`}
									placeId={placeId}
									elementName={`le commentaire de ${comment.pseudo}`}
									userEmail={userEmail || ''}
									isEmailVerified={isEmailVerified}
									userStatus={userStatus}
									type={'comment'}
									className={'-comment'}
								/>
							</div>
						</div>
						<p className="comment">{comment.comment}</p>
					</div>
				))}
				{allComments.length > 5 && (
					<button
						className="evaluation__usersComments__button"
						onClick={fetchAllComments}
						title="Voir plus"
					>
						Voir plus de commentaires
					</button>
				)}
			</div>

			<Modal
				isOpen={showModal}
				onClose={() => setShowModal(false)}
				className={'modalComment'}
			>
				<div className="evaluation__usersComments">
					<h3>Tous les commentaires</h3>
					{allComments.map((comment, index) => (
						<div key={index} className="evaluation__userComment">
							<div className="userComment">
								<div className="userComment__info">
									<div className="userComment__badge">
										{/* Affiche le badge si un niveau est trouvé */}
										{userLevels[comment.userId] !==
											undefined &&
											userLevels[comment.userId] > 0 && (
												<img
													src={getBadgeByLevel(
														userLevels[
															comment.userId
														]
													)}
													alt="Badge utilisateur"
													title={`Niveau: ${
														userLevels[
															comment.userId
														]
													}`}
												/>
											)}
									</div>
									<strong>
										{comment.pseudo || 'Anonyme'}
									</strong>{' '}
									<Rating
										data={{
											average: comment.rating,
											ratingsCount: 1
										}}
										variant="evaluation"
									/>
								</div>
								<div className="comment-date-report">
									({formatDate(comment.timestamp)})
									<ReportButton
										commentId={`${placeId}_${
											comment.userId || index
										}`}
										placeId={placeId}
										elementName={`le commentaire de ${comment.pseudo}`}
										userEmail={userEmail || ''}
										isEmailVerified={isEmailVerified}
										userStatus={userStatus}
										type={'comment'}
										className={'-comment'}
									/>
								</div>
							</div>
							<p className="comment">{comment.comment}</p>
						</div>
					))}
				</div>
			</Modal>
		</div>
	)
}

// Composant d'étoile pour la notation
const Star = ({ filled, onMouseEnter, onMouseLeave, onClick }) => (
	<span
		className={`star ${
			filled ? 'rating__star-full' : 'rating__star-empty'
		}`}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		onClick={onClick}
	>
		★
	</span>
)

export default Evaluation
