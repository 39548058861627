import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import beletteMap from '../../assets/beletteMap.png'

function Cgu() {
	useEffect(() => {
		// Remet le scroll en haut de la page lors du chargement du composant
		window.scrollTo(0, 0)
	}, [])

	return (
		<main className="cgu">
			<div className="cgu__title">
				<img src={beletteMap} alt="Logo du site, une belette marron" />
				<div>
					<h1>WeaselMap</h1>
					<h2>
						Mentions légales & Conditions Générales d'Utilisation
						(CGU)
					</h2>
				</div>
			</div>

			<div className="cgu__corps">
				<h2>Mentions légales</h2>
				<p>
					<strong>Hebergeur :</strong> OVH - 2 rue Kellermann 59100
					Roubaix - France
				</p>
				<h2>Conditions Générales d'Utilisation (CGU)</h2>
				<p>
					<strong>
						Derni&egrave;re mise &agrave; jour : 24 novembre 2024
					</strong>
				</p>
				<p>
					Bienvenue sur WeaselMap ! En utilisant notre application,
					vous acceptez pleinement les pr&eacute;sentes Conditions
					G&eacute;n&eacute;rales d&rsquo;Utilisation (CGU). Si vous
					n&apos;acceptez pas ces termes, vous ne devez pas utiliser
					l&apos;application.
				</p>
				<hr />
				<h3>
					<strong>1. Pr&eacute;sentation de la Web App</strong>
				</h3>
				<p>
					1.1. <strong>Propri&eacute;taire</strong>
					<br />
					L&apos;application WeaselMap est &eacute;dit&eacute;e par M.
					BASUYAU Christian, domicili&eacute; au 31 Rue Achille
					Carlier 59530 LE QUESNOY - FRANCE et immatricul&eacute; sous
					le num&eacute;ro [SIRET/num&eacute;ro
					d&apos;enregistrement].
				</p>
				<p>
					1.2. <strong>Objet</strong>
					<br />
					<p>
						WeaselMap est une application web accessible via
						"www.weaselmap.fr". Elle a pour objectif de permettre
						aux utilisateurs de :
					</p>
					<ul>
						<li>
							D&eacute;couvrir et consulter des lieux culturels,
							touristiques ou insolites, accompagn&eacute;s de
							descriptions et de photos partag&eacute;es par la
							communaut&eacute;.
						</li>
						<li>
							Ajouter et partager leurs propres lieux favoris et
							des photos associ&eacute;es avec d&rsquo;autres
							utilisateurs.
						</li>
						<li>
							Recevoir des recommandations personnalis&eacute;es
							en fonction des pr&eacute;f&eacute;rences
							exprim&eacute;es (tags, localisation, etc.).
						</li>
						<li>
							Partager et consulter des &eacute;v&eacute;nements
							&agrave; dur&eacute;e limit&eacute;e
							organis&eacute;s dans des zones g&eacute;ographiques
							sp&eacute;cifiques.
						</li>
					</ul>
					<p>
						L&rsquo;application s&rsquo;adresse &agrave; toute
						personne souhaitant enrichir ses d&eacute;couvertes
						locales ou touristiques et partager ses
						exp&eacute;riences avec une communaut&eacute;
						engag&eacute;e.
					</p>
					<p>
						En utilisant l&rsquo;application, vous acceptez de
						respecter les pr&eacute;sentes CGU d&eacute;taillant les
						conditions d&rsquo;acc&egrave;s, d&rsquo;utilisation et
						de partage des contenus.
					</p>
				</p>
				<hr />
				<h3>
					<strong>2. Acc&egrave;s et Utilisation</strong>
				</h3>
				<p>
					2.1. <strong>Acc&egrave;s</strong>
					<br />
					L&apos;acc&egrave;s &agrave; l&apos;application est
					r&eacute;serv&eacute; aux utilisateurs majeurs ou mineurs
					avec autorisation parentale. Certains services peuvent
					n&eacute;cessiter une inscription ou une authentification
					via Firebase.
				</p>
				<p>
					2.2. <strong>Inscription et Compte</strong>
				</p>
				<ul>
					<li>
						Vous devez fournir des informations exactes lors de
						votre inscription.
					</li>
					<li>
						L&rsquo;utilisateur est responsable de la
						confidentialit&eacute; de ses identifiants.
					</li>
					<li>
						Les comptes anonymes disposent de fonctionnalit&eacute;s
						limit&eacute;es.
					</li>
				</ul>
				<p>
					2.3. <strong>Utilisation</strong>
					<br />
					Vous vous engagez &agrave; :
				</p>
				<ul>
					<li>
						Ne pas utiliser l&apos;application &agrave; des fins
						ill&eacute;gales ou contraires &agrave; la morale.
					</li>
					<li>
						Respecter les droits d&apos;autres utilisateurs et des
						tiers.
					</li>
				</ul>
				<hr />
				<h3>
					<strong>3. Services et Fonctionnalit&eacute;s</strong>
				</h3>
				<p>
					3.1. <strong>Services gratuits et payants</strong>
					<p>
						WeaselMap propose des services payants en compléments
						des fonctionnalit&eacute;s gratuites pour les
						utilisateurs disposant d&apos;un compte professionnel.
						Plusieurs offres sont disponibles :
					</p>
					<ul>
						<li>
							<strong>
								Publication d&rsquo;&eacute;v&eacute;nements
								&agrave; dur&eacute;e limit&eacute;e (paiement
								unitaire)
							</strong>{' '}
							: Les comptes professionnels peuvent publier un
							&eacute;v&eacute;nement visible pendant une
							dur&eacute;e maximale de 45 jours.
						</li>
						<li>
							<strong>Abonnement Formule 1</strong> : Permet
							&agrave; la structure de publier davantage de photos
							de haute qualit&eacute; sur sa page
							d&eacute;di&eacute;e, mettre le lien vers le site
							externe de la stucture et indiquer un moyen de
							contact, offrant ainsi une pr&eacute;sentation
							enrichie et plus attractive.
						</li>
						<li>
							<strong>Abonnement Formule 2</strong> : les
							fonctionnaliés et aventages de l'abonnement Formule
							1 + la possibilité de publier jusqu&rsquo;&agrave; 5
							&eacute;v&eacute;nements par mois sans coût
							supplémentaire (d&rsquo;une dur&eacute;e maximale de
							45 jours chacun).
						</li>
					</ul>
					<p>
						Les d&eacute;tails des tarifs et conditions des
						abonnements sont disponibles sur la rubrique : "Ma page
						Pro".
					</p>
					<p>
						3.2. <strong>Modalit&eacute;s de paiement</strong>
					</p>
					<ul>
						<li>
							Les paiements sont effectu&eacute;s via [nom des
							moyens de paiement disponibles, ex. carte bancaire,
							PayPal, etc.].
						</li>
						<li>
							Les services payants sont activ&eacute;s d&egrave;s
							la confirmation du paiement.
						</li>
						<li>
							Aucun remboursement ne sera accord&eacute; pour les
							services ou abonnements d&eacute;j&agrave;
							utilis&eacute;s, sauf cas pr&eacute;vus par la
							l&eacute;gislation en vigueur.
						</li>
					</ul>
					<p>
						3.3.{' '}
						<strong>
							Responsabilit&eacute; des contenus payants
						</strong>
						<br />
						Les utilisateurs professionnels sont responsables des
						informations et visuels qu&rsquo;ils publient via les
						services payants. Ces contenus doivent respecter les
						r&egrave;gles d&eacute;crites dans ces CGU et ne pas
						enfreindre les droits des tiers.
					</p>
					<p>
						3.4.{' '}
						<strong>
							Partage d&rsquo;&eacute;v&eacute;nements
						</strong>
						<br />
						Les utilisateurs ayant souscrit à l'une des offres
						décrites au point 3.1 peuvent proposer des
						&eacute;v&eacute;nements &agrave; dur&eacute;e
						limit&eacute;e, visibles par la communaut&eacute;. Les
						informations fournies doivent &ecirc;tre
						pr&eacute;cises, et les &eacute;v&eacute;nements doivent
						respecter les lois et r&egrave;glements applicables.
					</p>
					<p>
						3.5. <strong>Notation et commentaires</strong>
						<br />
						Les utilisateurs connect&eacute;s peuvent &eacute;valuer
						et commenter les lieux. Ces avis doivent rester
						respectueux et conformes &agrave; la l&eacute;gislation.
						L'équipe WeaselMap se r&eacute;serve le droit de
						mod&eacute;rer ou de supprimer tout contenu
						inappropri&eacute;.
					</p>
					<p>
						3.6. <strong>Ajout de lieux et de photos</strong>
						<br />
						Les utilisateurs ont la possibilit&eacute;
						d&rsquo;ajouter de nouveaux lieux et de partager des
						photos associ&eacute;es. En publiant un contenu, ils
						s&rsquo;engagent &agrave; respecter les r&egrave;gles
						suivantes :
					</p>
					<ul>
						<li>
							Les informations partag&eacute;es doivent &ecirc;tre
							exactes et v&eacute;ridiques.
						</li>
						<li>
							Les photos doivent &ecirc;tre pertinentes,
							respectueuses et ne contenir aucun contenu
							ill&eacute;gal, offensant, diffamatoire,
							pornographique, violent ou portant atteinte aux
							droits des tiers.
						</li>
						<li>
							Les utilisateurs doivent d&eacute;tenir tous les
							droits n&eacute;cessaires sur les photos
							publi&eacute;es ou avoir obtenu l&rsquo;autorisation
							du d&eacute;tenteur des droits.
						</li>
					</ul>
					<p>
						En cas de violation de ces r&egrave;gles, l'équipe
						WeaselMap se r&eacute;serve le droit de supprimer le
						contenu concern&eacute;, voire de suspendre ou de
						cl&ocirc;turer le compte utilisateur.
					</p>
					<p>
						3.7.{' '}
						<strong>Recommandations personnalis&eacute;es</strong>
						<br />
						Les suggestions de lieux sont bas&eacute;es sur vos
						pr&eacute;f&eacute;rences (tags, localisation, etc.) que
						vous acceptez en utilisant cette fonctionnalit&eacute;.
					</p>
				</p>
				<hr />
				<h3>
					<strong>
						4. Protection des Donn&eacute;es Personnelles
					</strong>
				</h3>
				<p>
					4.1. <strong>Collecte des donn&eacute;es</strong>
					<br />
					Vos donn&eacute;es (email, pr&eacute;f&eacute;rences,
					localisation, etc.) sont collect&eacute;es dans le respect
					du{' '}
					<strong>
						R&egrave;glement G&eacute;n&eacute;ral sur la Protection
						des Donn&eacute;es (RGPD)
					</strong>{' '}
					et de la{' '}
					<strong>Loi Informatique et Libert&eacute;s</strong>.
				</p>
				<p>
					4.2.{' '}
					<strong>Droit d&apos;acc&egrave;s et suppression</strong>
					<br />
					Vous pouvez acc&eacute;der, rectifier ou demander la
					suppression de vos donn&eacute;es en envoyant un courriel à
					l'adresse "contact@weaselmap.fr" ou en complétant le
					formulaire de contact disponible depuis la Web-App.
				</p>
				<p>
					4.3. <strong>Cookies et suivi</strong>
					<br />
					<p>
						Nous utilisons uniquement des cookies strictement
						n&eacute;cessaires pour le bon fonctionnement de
						l&rsquo;application, tels que ceux permettant :
					</p>
					<ul>
						<li>La gestion des sessions utilisateur.</li>
						<li>La s&eacute;curit&eacute; de la navigation.</li>
						<li>
							L&rsquo;am&eacute;lioration des performances
							techniques.
						</li>
					</ul>
					<p>
						Aucun cookie optionnel, destin&eacute; au suivi des
						utilisateurs ou &agrave; des fins publicitaires,
						n&rsquo;est utilis&eacute; sur WeaselMap. Vous pouvez
						toutefois g&eacute;rer ou bloquer ces cookies essentiels
						via les param&egrave;tres de votre navigateur, bien que
						cela puisse alt&eacute;rer certaines
						fonctionnalit&eacute;s de l&rsquo;application.
					</p>
				</p>
				<p>
					4.4. <strong>Autorisation des contacts par email</strong>
					<br />
					<p>
						Lors de l'inscription, les utilisateurs peuvent choisir
						d'autoriser ou non l'équipe WeaselMap à les contacter
						par email à des fins d'information ou de communication
						liées à l'application.
					</p>
				</p>
				<ul>
					<li>
						Cette autorisation est facultative et peut être modifiée
						à tout moment dans les paramètres du compte.
					</li>
					<li>
						En cas de refus ou de retrait de l'autorisation, aucun
						email ne sera envoyé, sauf pour des communications
						essentielles au fonctionnement de l'application (exemple
						: notifications de sécurité, mises à jour des CGU,
						etc.).
					</li>
				</ul>
				<hr />
				<h3>
					<strong>5. Responsabilit&eacute;s</strong>
				</h3>
				<p>
					5.1. <strong>Disponibilit&eacute; du service</strong>
					<br />
					Bien que nous nous efforcions d&rsquo;assurer une
					disponibilit&eacute; continue, WeaselMap ne peut garantir un
					acc&egrave;s sans interruption.
				</p>
				<p>
					5.2. <strong>Limitations</strong>
					<br />
					WeaselMap ne saurait &ecirc;tre tenu responsable des :
				</p>
				<ul>
					<li>Pertes de donn&eacute;es ou bugs techniques.</li>
					<li>
						Mauvais usages des informations disponibles sur
						l&rsquo;application.
					</li>
				</ul>
				<hr />
				<h3>
					<strong>6. Propriété Intellectuelle</strong>
				</h3>
				<p>
					6.1. <strong>Contenus de WeaselMap</strong>
					<br />
					Tous les contenus disponibles sur WeaselMap (textes, images,
					logos, code, etc.), &agrave; l&apos;exception des contenus
					ajout&eacute;s par les utilisateurs, sont la
					propri&eacute;t&eacute; exclusive de M. BASUYAU Christian.
					Toute reproduction, distribution, modification ou
					utilisation sans autorisation pr&eacute;alable est
					strictement interdite.
				</p>
				<p>
					6.2.{' '}
					<strong>
						Contenus ajout&eacute;s par les utilisateurs
					</strong>
					<br />
					En partageant des photos ou d&apos;autres contenus sur
					WeaselMap, les utilisateurs garantissent qu&apos;ils
					disposent des droits n&eacute;cessaires pour publier ces
					&eacute;l&eacute;ments (exemple : &ecirc;tre l&rsquo;auteur
					ou avoir l&rsquo;autorisation du d&eacute;tenteur des
					droits).
				</p>
				<p>En soumettant du contenu :</p>
				<ul>
					<li>
						Les utilisateurs conservent leurs droits de
						propri&eacute;t&eacute; intellectuelle.
					</li>
					<li>
						Ils accordent &agrave; WeaselMap une licence non
						exclusive, gratuite, mondiale et limit&eacute;e &agrave;
						l&apos;exploitation n&eacute;cessaire au fonctionnement
						de l&apos;application. Cela inclut l&apos;affichage, la
						reproduction et la diffusion des photos sur
						l&apos;application.
					</li>
					<li>
						WeaselMap ne revendique pas la propri&eacute;t&eacute;
						des contenus, mais se r&eacute;serve le droit de les
						supprimer en cas de non-respect des pr&eacute;sentes
						CGU.
					</li>
				</ul>
				<hr />
				<h3>
					<strong>
						7. R&eacute;siliation et Suppression de Compte
					</strong>
				</h3>
				<p>
					WeaselMap se r&eacute;serve le droit de suspendre ou de
					supprimer un compte utilisateur en cas de non-respect des
					CGU.
				</p>
				<hr />
				<h3>
					<strong>8. Modification des CGU</strong>
				</h3>
				<p>
					Nous nous r&eacute;servons le droit de modifier ces
					conditions &agrave; tout moment. Les utilisateurs seront
					inform&eacute;s des mises &agrave; jour via email ou
					notification.
				</p>
				<hr />
				<h3>
					<strong>9. Loi Applicable et Litiges</strong>
				</h3>
				<p>
					Ces CGU sont r&eacute;gies par la loi fran&ccedil;aise. En
					cas de litige, les parties s&apos;engagent &agrave;
					rechercher une solution amiable avant tout recours
					juridique.
				</p>
				<hr />
				<h3>
					<strong>10. Contact</strong>
				</h3>
				<p>
					Pour toute question relative &agrave; ces CGU, veuillez nous
					contacter &agrave; :
				</p>
				<ul>
					<li>
						<strong>Email :</strong> contact@weaselmap.fr
					</li>
					<li>
						<strong>Adresse :</strong> 31, Rue Achille Carlier 59530
						LE QUESNOY - FRANCE
					</li>
				</ul>
				<hr />
				<p>
					<strong>
						En utilisant notre application, vous reconnaissez avoir
						lu et accept&eacute; ces conditions.
					</strong>
				</p>
			</div>

			<Link to="/">Retourner sur la page d'accueil</Link>
		</main>
	)
}

export default Cgu
