import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from './firebaseConfig'

const ProtectedRouteAdmin = ({ children }) => {
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(true)

	// Charger les UID autorisés depuis .env
	const adminUids = process.env.REACT_APP_ADMIN_UIDS
		? process.env.REACT_APP_ADMIN_UIDS.split(',')
		: []

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setUser(user) // Met à jour l'état utilisateur
			setLoading(false) // Arrête le chargement une fois l'état utilisateur connu
		})

		return () => unsubscribe() // Nettoyage lors du démontage du composant
	}, [])

	if (loading) {
		return <div>Chargement...</div> // Optionnel : Loader pendant que Firebase vérifie l'état utilisateur
	}

	// Si l'utilisateur n'est pas connecté ou n'est pas un admin autorisé
	if (!user || !adminUids.includes(user.uid)) {
		return <Navigate to="/" />
	}

	return children
}

export default ProtectedRouteAdmin
