import { useState, useEffect } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import { firestore } from '../../../services/firebaseConfig'
import tagsList from '../../../data/tags.json'

const TagsBox = ({
	userId,
	userPreferences = {}, // Valeur par défaut pour éviter les erreurs si userPreferences est undefined
	onPreferencesUpdate,
	isAnonymous,
	onClose
}) => {
	const [selectedTags, setSelectedTags] = useState(
		userPreferences.preferences || [] // Défaut à un tableau vide
	)
	const [city, setCity] = useState(userPreferences.city || '')
	const [sliderValue, setSliderValue] = useState(userPreferences.radius || 30)
	const [confirmationMessage, setConfirmationMessage] = useState('')

	useEffect(() => {
		// Mettre à jour les préférences lorsque props userPreferences change, avec valeurs par défaut
		setSelectedTags(userPreferences.preferences || [])
		setCity(userPreferences.city || '')
		setSliderValue(userPreferences.radius || 30)
	}, [userPreferences])

	const handleTagClick = (tag) => {
		setSelectedTags((prev) =>
			prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
		)
	}

	const savePreferences = async () => {
		const updatedPreferences = {
			preferences: selectedTags,
			city: city,
			radius: sliderValue
		}

		try {
			if (!isAnonymous) {
				const userDocRef = doc(firestore, 'users', userId)
				await updateDoc(userDocRef, updatedPreferences)

				// Enregistrer également dans le localStorage
				localStorage.setItem(
					'userPreferences',
					JSON.stringify(selectedTags)
				)
				localStorage.setItem('userCity', city)
				localStorage.setItem('userSliderValue', sliderValue)
			} else {
				// Enregistrer dans le localStorage pour les utilisateurs anonymes
				localStorage.setItem(
					'userPreferences',
					JSON.stringify(selectedTags)
				)
				localStorage.setItem('userCity', city)
				localStorage.setItem('userSliderValue', sliderValue)
			}

			// Mettre à jour les préférences dans le composant parent
			if (onPreferencesUpdate) {
				onPreferencesUpdate(updatedPreferences)
			}

			// Afficher le message de confirmation
			setConfirmationMessage(
				'Vos préférences ont été mises à jour avec succès !'
			)

			// Réinitialiser le flag pour ne plus afficher la popup
			localStorage.setItem('hasCheckedPreferences', 'true')

			if (onClose) {
				onClose()
			}

			setTimeout(() => setConfirmationMessage(''), 3000)
		} catch (error) {
			console.error(
				'Erreur lors de la mise à jour des préférences:',
				error
			)
			setConfirmationMessage(
				'Une erreur est survenue lors de la mise à jour.'
			)
		}
	}

	return (
		<div className="tagsBox">
			<h3>Vos préférences :</h3>
			<div className="tagsBox__list">
				{tagsList.tags.map((tag) => (
					<button
						key={tag}
						type="button"
						className={selectedTags.includes(tag) ? 'selected' : ''}
						onClick={() => handleTagClick(tag)}
					>
						{tag}
					</button>
				))}
			</div>

			<div className="tagsBox__cityAndSlider">
				{/* Champ pour la ville */}
				<div className="tagsBox__city">
					<label htmlFor="city">Votre ville : </label>
					<input
						type="text"
						id="city"
						value={city}
						onChange={(e) => setCity(e.target.value)}
						placeholder="Entrez votre ville"
					/>
				</div>

				{/* Slider pour une valeur numérique */}
				<div className="tagsBox__slider">
					<label htmlFor="slider">
						Distance d'affichage : {sliderValue} km
					</label>
					<input
						type="range"
						id="slider"
						min="5"
						max="30"
						value={sliderValue}
						onChange={(e) => setSliderValue(e.target.value)}
					/>
				</div>
			</div>

			{/* Bouton pour sauvegarder */}
			<button className="tagsBox__majButton" onClick={savePreferences}>
				Mettre à jour
			</button>

			{/* Afficher le message de confirmation */}
			<p className="tagsBox__confirmation">{confirmationMessage}</p>
		</div>
	)
}

export default TagsBox
