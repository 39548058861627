import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { auth } from './firebaseConfig'

import Loading from '../components/Objects/Loading'

const ProtectedRoute = ({ children }) => {
	const [user, setUser] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setUser(user) // Met à jour l'état utilisateur
			setLoading(false) // Arrête le chargement une fois l'état utilisateur connu
		})

		return () => unsubscribe() // Nettoyage lors du démontage du composant
	}, [])

	if (loading) {
		return <Loading /> // Optionnel : Loader pendant que Firebase vérifie l'état utilisateur
	}

	// Si l'utilisateur n'est pas connecté (ni par email ni en mode anonyme)
	if (!user) {
		return <Navigate to="/auth-choice" />
	}

	return children
}

export default ProtectedRoute
