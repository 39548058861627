import { useState } from 'react'
import { logIn, resetPassword } from '../../services/authService'
import { useNavigate } from 'react-router-dom'

///////////////Components///////////////
import BackButton from '../../components/Objects/Btn-Back'

const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const [resetEmail, setResetEmail] = useState('')
	const [message, setMessage] = useState('')
	const [showResetForm, setShowResetForm] = useState(false)
	const navigate = useNavigate()

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			await logIn(email, password)
			navigate('/')
		} catch (err) {
			setError('Utilisateur ou mot de passe incorrect')
		}
	}

	const handlePasswordReset = async (e) => {
		e.preventDefault()
		try {
			await resetPassword(resetEmail)
			setMessage(
				'Un email de réinitialisation de mot de passe a été envoyé.'
			)
		} catch (err) {
			setError(err.message)
		}
	}

	// Réinitialiser l'erreur lors de la saisie de l'email ou du mot de passe
	const handleEmailChange = (e) => {
		setEmail(e.target.value)
		setError('') // Réinitialise l'erreur
	}

	const handlePasswordChange = (e) => {
		setPassword(e.target.value)
		setError('') // Réinitialise l'erreur
	}

	const handleResetEmailChange = (e) => {
		setResetEmail(e.target.value)
		setError('') // Réinitialise l'erreur
	}

	return (
		<div className="login">
			{showResetForm ? (
				<>
					<h2>Réinitialiser le mot de passe</h2>
					<form onSubmit={handlePasswordReset}>
						<input
							type="email"
							placeholder="Email"
							value={resetEmail}
							onChange={handleResetEmailChange}
							required
						/>
						<button className="login__initButton" type="submit">
							Confirmer
						</button>
						{message && <p>{message}</p>}
						{error && <p>{error}</p>}
						<button
							onClick={() => {
								setShowResetForm(false)
								setError('') // Réinitialiser l'erreur lors du changement de formulaire
							}}
							className="login__initButton-backButton"
							type="button"
						>
							<span className="material-symbols-outlined spanBackButton">
								arrow_back
							</span>
							Retour page de connexion
						</button>
					</form>
				</>
			) : (
				<div>
					<form onSubmit={handleSubmit}>
						<label htmlFor="email">Adresse mail</label>
						<input
							type="email"
							id="email"
							value={email}
							onChange={handleEmailChange}
							required
						/>

						<label htmlFor="password">Mot de passe</label>
						<input
							type="password"
							id="password"
							value={password}
							onChange={handlePasswordChange}
							required
						/>
						<button className="login__loginButton" type="submit">
							Connexion
						</button>
						{error && <p>{error}</p>}

						<button
							onClick={() => {
								setShowResetForm(true)
								setError('') // Réinitialiser l'erreur lors du changement de formulaire
							}}
							className="login__resetButton"
							type="button"
						>
							Mot de passe oublié?
						</button>

						<BackButton className={'backButton'} type={'button'}>
							Retour au choix de connexion
						</BackButton>
					</form>
				</div>
			)}
		</div>
	)
}

export default Login
