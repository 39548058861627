import { useState } from 'react'
import { Link } from 'react-router-dom'
import { signUp } from '../../services/authService'
import { useNavigate } from 'react-router-dom'
import {
	doc,
	setDoc,
	getDocs,
	collection,
	query,
	where
} from 'firebase/firestore'
import { firestore } from '../../services/firebaseConfig'

///////////////Components///////////////
import BackButton from '../../components/Objects/Btn-Back'
import workList from '../../data/work.json'

const Signup = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [pseudo, setPseudo] = useState('')
	const [siret, setSiret] = useState('')
	const [rna, setRna] = useState('')
	const [selectedField, setSelectedField] = useState([])
	const [status, setStatus] = useState('particulier')
	const [cguAccepted, setCguAccepted] = useState(false)
	const [acceptContact, setAcceptContact] = useState(false)
	const [error, setError] = useState('')
	const navigate = useNavigate()

	//RegEx
	const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/
	const pseudoRegex = /^[a-zA-Z0-9_-]{3,15}$/
	const structureNameRegex = /^[a-zA-Z0-9À-ÿ\s'-]{3,30}$/
	const siretRegex = /^\d{14}$/
	const rnaRegex = /^W\d{9}$/
	const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,25}$/

	// Fonction pour valider l'email
	const validateEmail = (email) => emailRegex.test(email)

	// Fonction pour valider le pseudo
	const validatePseudo = (pseudo) => pseudoRegex.test(pseudo)

	// Fonction pour valider le mot de passe
	const validatePassword = (password) => passwordRegex.test(password)

	// Fonction pour valider le nom de la structure
	const validateStructureName = (name) => structureNameRegex.test(name)

	// Fonction pour valider le numéro de SIRET
	const validateSIRET = (siret) => siretRegex.test(siret)

	// Fonction pour valider le numéro de SIRET
	const validateRNA = (rna) => rnaRegex.test(rna)

	// Fonction de soumission du formulaire
	const handleSubmit = async (e) => {
		e.preventDefault()

		// Vérification de l'email
		if (!validateEmail(email)) {
			setError("L'adresse email n'est pas valide.")
			return
		}

		// Vérification du pseudo
		if (status === 'particulier' && !validatePseudo(pseudo)) {
			setError(
				'Le pseudo doit contenir entre 3 et 15 caractères (lettres, chiffres, tirets ou underscores).'
			)
			return
		}

		// Vérification du mot de passe
		if (!validatePassword(password)) {
			setError(
				'Le mot de passe doit contenir entre 8 et 25 caractères avec au moins un chiffre et une majuscule.'
			)
			return
		}

		// Vérification du nom de structure
		if (
			(status === 'pro' || status === 'asso') &&
			!validateStructureName(pseudo)
		) {
			setError(
				'Le nom de la structure doit contenir entre 3 et 30 caractères (lettres, chiffres, espaces, tirets ou apostrophes).'
			)
			return
		}

		// Vérification du SIRET si le statut est "pro"
		if (status === 'pro' && !validateSIRET(siret)) {
			setError(
				"Merci d'indiquer un numéro de SIRET valide (14 chiffres)."
			)
			return
		}

		// Vérification du RNA si le statut est "asso"
		if (status === 'asso' && !validateRNA(rna)) {
			setError(
				"Merci d'indiquer un identifiant RNA valide (W + 9 chiffres)."
			)
			return
		}

		// Vérifier que les deux mots de passe correspondent
		if (password !== confirmPassword) {
			setError('Les mots de passe ne correspondent pas.')
			return
		}

		// Vérifier les doublons de structure ou de SIRET
		const isDuplicate = await checkDuplicate()
		if (isDuplicate) {
			return // Si un doublon est trouvé, arrêter le processus
		}

		try {
			// Créer un compte utilisateur
			const userCredential = await signUp(email, password)
			const user = userCredential.user

			// Enregistrer les informations de l'utilisateur dans Firestore
			const userDocRef = doc(firestore, 'users', user.uid)
			await setDoc(userDocRef, {
				email: user.email,
				pseudo: pseudo,
				activity: status === 'pro' || 'asso' ? selectedField : null,
				statut: status,
				siret: status === 'pro' ? siret : null,
				rna: status === 'asso' ? rna : null,
				acceptContact: acceptContact
			})

			// Redirection vers la page d'accueil
			navigate('/')
		} catch (err) {
			if (err.code === 'auth/email-already-in-use') {
				setError('Cette adresse email est déjà utilisée.')
			} else {
				setError(
					'Une erreur est survenue. Veuillez réessayer plus tard.'
				)
			}
		}
	}

	// Fonction pour vérifier les doublons de structure ou de SIRET
	const checkDuplicate = async () => {
		try {
			// Si le statut est particulier vérifier le pseudo
			if (status === 'particulier') {
				const structureQuery = query(
					collection(firestore, 'users'),
					where('pseudo', '==', pseudo)
				)
				const structureSnapshot = await getDocs(structureQuery)

				if (!structureSnapshot.empty) {
					setError('Ce pseudo est déjà utilisé.')
					return true
				}
			}

			// Si le statut est professionnel ou association, vérifier le nom de la structure
			if (status === 'pro' || status === 'asso') {
				const structureQuery = query(
					collection(firestore, 'users'),
					where('pseudo', '==', pseudo)
				)
				const structureSnapshot = await getDocs(structureQuery)

				if (!structureSnapshot.empty) {
					setError('Le nom de cette structure est déjà utilisé.')
					return true
				}
			}

			// Si le statut est professionnel, vérifier le numéro de SIRET
			if (status === 'pro') {
				const siretQuery = query(
					collection(firestore, 'users'),
					where('siret', '==', siret)
				)
				const siretSnapshot = await getDocs(siretQuery)

				if (!siretSnapshot.empty) {
					setError('Ce numéro de SIRET est déjà utilisé.')
					return true
				}
			}

			// Si le statut est une association, vérifier le numéro de RNA
			if (status === 'asso') {
				const rnaQuery = query(
					collection(firestore, 'users'),
					where('rna', '==', rna)
				)
				const rnaSnapshot = await getDocs(rnaQuery)

				if (!rnaSnapshot.empty) {
					setError('Cet identifiant RNA est déjà utilisé.')
					return true
				}
			}
		} catch (error) {
			setError('Erreur lors de la vérification des doublons.')
			console.error('Erreur lors de la vérification des doublons:', error)
			return true
		}
		return false
	}

	// Fonction pour gérer la sélection/désélection d'un tag ou d'un field pour l'activité des pro
	const handleTagClick = (tag) => {
		// Si le statut est "pro" ou "asso", permettre seulement un field
		setSelectedField([tag])
	}

	// Fonction pour gérer la sélection du statut
	const handleStatusChange = (e) => {
		setStatus(e.target.value)
		setError('') // Réinitialiser l'erreur lorsqu'on change de statut
	}

	return (
		<div className="signup">
			<h2>Création d'un compte</h2>
			<form onSubmit={handleSubmit}>
				<label htmlFor="email">Votre adresse mail</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				<label htmlFor="password">Votre mot de passe</label>
				<input
					type="password"
					id="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
				<label htmlFor="confirmPassword">
					Confirmer votre mot de passe
				</label>
				<input
					type="password"
					id="confirmPassword"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					required
				/>

				{/* Liste déroulante pour sélectionner le statut */}
				<h3>Personaliser votre expérience</h3>
				<label htmlFor="status">Vous êtes :</label>
				<select
					id="status"
					value={status}
					onChange={handleStatusChange}
					required
				>
					<option value="particulier">Un particulier</option>
					<option value="pro">Un professionnel</option>
					<option value="asso">Une association</option>
				</select>

				<label htmlFor="pseudo">
					{status === 'particulier' || status === ''
						? 'Votre pseudo'
						: 'Nom de la structure'}
				</label>
				<input
					type="text"
					id="pseudo"
					value={pseudo}
					onChange={(e) => setPseudo(e.target.value)}
					maxLength={status === 'particulier' ? 15 : 30} // Limiter à 30 caractères pour les noms de structure
					required
				/>
				{status === 'particulier' &&
					!validatePseudo(pseudo) &&
					pseudo.length > 0 && (
						<p className="error">
							Le pseudo doit contenir entre 3 et 15 caractères
							(lettres, chiffres, tirets ou underscores).
						</p>
					)}
				{(status === 'pro' || status === 'asso') &&
					!validateStructureName(pseudo) &&
					pseudo.length > 0 && (
						<p className="error">
							Le nom de la structure doit contenir entre 3 et 30
							caractères.
						</p>
					)}

				{/* Afficher le champ SIRET si le statut est professionnel */}

				{status === 'pro' && (
					<>
						<label htmlFor="siret">Numéro de SIRET</label>
						<input
							type="text"
							id="siret"
							value={siret}
							onChange={(e) => setSiret(e.target.value)}
							maxLength={14} // Limiter à 14 caractères
							required
						/>
						{!validateSIRET(siret) && siret.length > 0 && (
							<p className="error">
								Le numéro de SIRET doit contenir exactement 14
								chiffres.
							</p>
						)}
					</>
				)}

				{status === 'asso' && (
					<>
						<label htmlFor="rna">Identifiant RNA</label>
						<input
							type="text"
							id="rna"
							value={rna}
							onChange={(e) => setRna(e.target.value)}
							maxLength={10} // Limiter à 14 caractères
							required
						/>
						{!validateRNA(rna) && rna.length > 0 && (
							<p className="error">
								Le numéro du répertoire national des
								associations (RNA) doit commencer par un W suivi
								de 9 chiffres.
							</p>
						)}
					</>
				)}

				{/* Afficher la liste des tags */}
				<div className="tagsBox tagsBox-signup">
					{status === 'particulier' || status === '' ? (
						<>
							{/* TO DO : les tags ne se synchronise pas dès la premier connexion, il est nécessaire de recharger la page - entraine un probléme d'expérience utilisateur */}
							{/* <h4>Vos centres d'intérêts :</h4>
							<div className="tagsBox__list">
								{tagsList.tags.map((tag) => (
									<button
										key={tag}
										type="button"
										className={
											selectedTags.includes(tag)
												? 'selected'
												: ''
										}
										onClick={() => handleTagClick(tag)}
									>
										{tag}
									</button>
								))}
							</div>
							<p>
								Vous pourrez modifier vos préférences par la
								suite dans les paramètres.
							</p> */}
						</>
					) : (
						<>
							<h4>Votre secteur d'activité</h4>
							<div className="tagsBox__list">
								{workList.tags.map((tag) => (
									<button
										key={tag}
										type="button"
										className={
											selectedField.includes(tag)
												? 'selected'
												: ''
										}
										onClick={() => handleTagClick(tag)}
									>
										{tag}
									</button>
								))}
							</div>
						</>
					)}
				</div>

				<div className="signup__tick">
					<div className="signup__cgu">
						<label>
							<input
								className="signup__cgu-input"
								type="checkbox"
								checked={cguAccepted}
								onChange={(e) =>
									setCguAccepted(e.target.checked)
								}
								required
							/>
							J'ai lu et j'accepte les{' '}
							<Link
								to={'/WeaselMap-CGU'}
								target="_blank"
								rel="noopener noreferrer"
							>
								conditions générales d'utilisation
							</Link>
						</label>
					</div>

					<div className="signup__contact">
						<label>
							<input
								className="signup__cgu-input"
								type="checkbox"
								checked={acceptContact}
								onChange={(e) =>
									setAcceptContact(e.target.checked)
								}
							/>
							<span>
								J'accepte d'être contacté par mail pour des
								communications et mises à jour.
							</span>
						</label>
					</div>
				</div>
				<button
					className="login__loginButton"
					type="submit"
					// disabled={!cguAccepted}
					title="Créer un compte"
				>
					Créer un compte
				</button>
				{error && <p>{error}</p>}

				<BackButton className={'backButton'} type={'button'}>
					Retour au choix de connexion
				</BackButton>
			</form>
		</div>
	)
}

export default Signup
