import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import Carrousel from '../Carrousel'
import Modal from '../Modal'
import Rating from '../Rating'
import Evaluation from '../Evaluation'
import AddPlaceForm from '../Form-AddPlace'
import ReportButton from '../Btn-Report/BtnReport'
import CreateAccountButton from '../Btn-CreateAccount'
import AddToFavoritesButton from '../Btn-Favoris'

// Objets
import beletteMap from '../../../assets/beletteMap.png'

const PlaceCard = ({
	className,
	data,
	selectedPlaceId,
	userPreferences = {},
	userStatus,
	isEmailVerified,
	resendVerificationEmail,
	userId,
	userEmail,
	isAnonymous,
	onSeeMore,
	onSeeOnMap
}) => {
	const [selectedTags, setSelectedTags] = useState(
		userPreferences.preferences || [] // Défaut à un tableau vide
	)

	const [isModalAddFormOpen, setIsModalAddFormOpen] = useState(false)
	const [copyMessage, setCopyMessage] = useState(false)

	const openModalAddForm = () => {
		setIsModalAddFormOpen(true)
	}
	const closeModalAddForm = () => setIsModalAddFormOpen(false)

	useEffect(() => {
		// Mettre à jour les préférences lorsque props userPreferences change, avec valeurs par défaut
		setSelectedTags(userPreferences.preferences || [])
	}, [userPreferences])

	// Filtrer les données si un ID spécifique est fourni
	const filteredData = selectedPlaceId
		? data.filter((element) => element.id === selectedPlaceId)
		: data

	const copyToClipboard = (properties) => {
		const fullAddress = `${properties.address} ${properties.postalCode} ${properties.city}`
		navigator.clipboard
			.writeText(fullAddress)
			.then(() => {
				setCopyMessage(true)
				setTimeout(() => setCopyMessage(false), 2000) // Message disparaît après 1 seconde
			})
			.catch(() => {
				console.error("Erreur lors de la copie de l'adresse.")
			})
	}

	// **Vérifier si les données sont vides**
	if (filteredData.length === 0 && className === 'placeCard-aroundYou') {
		return (
			<div className={className}>
				<p>Aucun lieu disponible.</p>
				<p>
					Et si vous <span>partagiez vos lieux préférés</span> avec la
					communauté ?
				</p>
				{isAnonymous && (
					<>
						<p>
							Pour ajouter des lieux connectez vous ou créez un
							compte et devenez <span>pionier WeaselMap !</span>
						</p>
						<CreateAccountButton />
					</>
				)}
				{!isAnonymous && !isEmailVerified && (
					<p>
						Pour ajouter des lieux pensez à vérifier votre adresse
						mail et devenez pionier WeaselMap !
					</p>
				)}
				{/* Afficher le bouton pour ouvrir le formulaire d'ajout */}
				{!isAnonymous && isEmailVerified && (
					<button
						type="button"
						onClick={openModalAddForm}
						className="add-place-button"
					>
						<span className="material-symbols-outlined">
							add_location_alt
						</span>
						<span>Ajouter un lieu</span>
					</button>
				)}
				{/* Modal pour le formulaire d'ajout de lieu */}
				<Modal
					isOpen={isModalAddFormOpen}
					onClose={closeModalAddForm}
					className={'addPlaceForm__modal'}
				>
					<AddPlaceForm
						onClose={closeModalAddForm}
						userStatus={userStatus}
						userEmail={userEmail}
						userId={userId}
						existingData={null} // Pas de données existantes pour une création
						mode={'creation'} // Mode création
					/>
				</Modal>
			</div>
		)
	}

	return (
		<>
			{filteredData.map((element) => {
				// const { coordinates } = element.geometry
				const properties = element.properties
				const hasPictures =
					properties.pictures &&
					Object.keys(properties.pictures).length > 0

				// Trier les tags en plaçant les préférés de l'utilisateur en premier
				const sortedTags = properties.tags
					.slice() // Copier le tableau pour éviter de modifier les données d'origine
					.sort((a, b) => {
						const aIsPreferred = selectedTags?.includes(a)
						const bIsPreferred = selectedTags?.includes(b)
						return aIsPreferred === bIsPreferred
							? 0
							: aIsPreferred
							? -1
							: 1
					})

				// Déterminer si on affiche tous les tags ou seulement 3
				const tagsToDisplay =
					className === 'placeCard-nextOuting'
						? sortedTags
						: sortedTags.slice(0, 3)

				// Générer les tags avec condition
				const tags =
					tagsToDisplay && tagsToDisplay.length > 0 ? (
						tagsToDisplay.map((tag, index) => (
							<span
								key={index}
								className={`placeCard__tag ${
									selectedTags?.includes(tag)
										? 'selected'
										: ''
								}`}
							>
								{tag}
							</span>
						))
					) : (
						<span>Aucun tag disponible</span>
					)

				return (
					<div key={element.id} className={className}>
						{className === 'placeCard-aroundYou' && (
							<div
								role="button"
								className="placeCard-aroundYou__button"
								onClick={() => onSeeMore(element.id)}
							>
								<div className="placeCard__noteAndPosition">
									<Rating
										data={properties.rating}
										variant="menu1"
									/>
									<AddToFavoritesButton
										placeId={element.id}
										userId={userId}
										isAnonymous={isAnonymous}
										variant={'aroundYou'}
									/>
								</div>

								<h3>{properties.title}</h3>
								<div className="placeCard__tags">{tags}</div>
								{hasPictures ? (
									<Carrousel
										title={properties.title}
										pictures={properties.pictures}
										id={element.id}
										reduceView={true}
									/>
								) : (
									<div className="placeCard__no-images">
										Pas encore de photo pour ce lieu.
									</div>
								)}
							</div>
						)}

						{className === 'placeCard-nextOuting' && (
							<>
								<div className="placeCard__noteAndPosition">
									<Rating
										data={properties.rating}
										variant="menu2"
									/>
									<AddToFavoritesButton
										placeId={selectedPlaceId}
										userId={userId}
										isAnonymous={isAnonymous}
									/>
									{/* <span className="placeCard__position">
										XXkm
									</span> */}
								</div>
								<div className="placeCard-nextOuting__title">
									<h3>{properties.title}</h3>
									<button
										className="placeCard__seeOnMapButton"
										onClick={() => {
											onSeeOnMap(selectedPlaceId)
										}}
										title="Voir sur la carte"
									>
										<span className="material-symbols-outlined">
											travel_explore
										</span>
									</button>
								</div>
								<div className="placeCard__tags">{tags}</div>
								<ReportButton
									placeId={element.id}
									elementName={properties.title}
									userEmail={userEmail || ''}
									isEmailVerified={isEmailVerified}
									userStatus={userStatus}
									type={'place'}
									className={''}
								/>
								{hasPictures ? (
									<>
										<Carrousel
											title={properties.title}
											pictures={properties.pictures}
											id={element.id}
											reduceView={false}
										/>
									</>
								) : (
									<div className="placeCard__no-images">
										Pas encore de photo pour ce lieu.
									</div>
								)}
								<div className="placeCard__adresse-title">
									<span className="adresse-title">
										Adresse :{' '}
									</span>
									<span>
										{properties.address}{' '}
										{properties.postalCode}{' '}
										{properties.city}
									</span>
									<button
										className="placeCard__copyBtn"
										onClick={() =>
											copyToClipboard(properties)
										}
										title="Copier l'adresse"
									>
										<span className="material-symbols-outlined">
											content_copy
										</span>
									</button>
									{copyMessage && (
										<span className="placeCard__copy-message">
											Adresse copiée !
										</span>
									)}
								</div>

								<div className="placeCard__adresse-title">
									<div className="titleAndBtn">
										<h4 className="adresse-title">
											Description :
										</h4>
										{!isAnonymous && isEmailVerified && (
											<div className="updateBtn">
												<button
													type="button"
													onClick={openModalAddForm}
													className="reportButton"
												>
													<span className="buttonText">
														{userStatus === 'admin'
															? 'Modifier'
															: 'Proposer une modification'}
													</span>
													<span className="material-symbols-outlined">
														edit
													</span>
												</button>
											</div>
										)}
									</div>
									<span id="description">
										{properties.descriptionFR}
									</span>
									{properties.createdBy && (
										<div id="created">
											Ajouté par :{' '}
											<span>{properties.createdBy}</span>
										</div>
									)}
								</div>
								<Evaluation
									placeId={element.id}
									userStatus={userStatus}
									userEmail={userEmail || ''}
									isEmailVerified={isEmailVerified}
									isAnonymous={isAnonymous}
									userId={userId}
									onRatingChange={(newRating) =>
										console.log(
											`Nouvelle note: ${newRating}`
										)
									}
								/>

								<Modal
									isOpen={isModalAddFormOpen}
									onClose={closeModalAddForm}
									className={'addPlaceForm__modal'}
								>
									<AddPlaceForm
										onClose={closeModalAddForm}
										userStatus={userStatus}
										userEmail={userEmail}
										userId={userId}
										existingData={filteredData}
										placeId={selectedPlaceId}
										mode={'update'}
									/>
								</Modal>
							</>
						)}

						{className === 'placeCard-favoris' && (
							<div
								role="button"
								className="placeCard-aroundYou__button"
								onClick={() => onSeeMore(element.id)}
							>
								<h3>
									{properties.title} -{' '}
									<span>
										{properties.postalCode}{' '}
										{properties.city}
									</span>
								</h3>{' '}
								<div className="placeCard__tags">{tags}</div>
								{hasPictures ? (
									<Carrousel
										title={properties.title}
										pictures={properties.pictures}
										id={element.id}
										reduceView={true}
									/>
								) : (
									<div className="placeCard__no-images">
										Pas encore de photo pour ce lieu.
									</div>
								)}
							</div>
						)}
					</div>
				)
			})}
			{className === 'placeCard-aroundYou' && (
				<footer className={`${className} footer`}>
					<div className="footer__message">
						<img src={beletteMap} alt="Belette avec une carte" />
						<p>
							Vous avez vu tous les lieux proposés par la
							communauté ici.
						</p>
					</div>
					{!isAnonymous && (
						<p>
							Et si vous partiez à l'aventure pour{' '}
							<span>découvrir de nouveau lieux à partager</span> ?
						</p>
					)}
					{isAnonymous && (
						<>
							<p>
								Pour ajouter des lieux connectez vous ou créez
								un compte et devenez :<br />
								<span>pionier WeaselMap !</span>
							</p>
							<CreateAccountButton />
						</>
					)}
					{!isAnonymous && !isEmailVerified && (
						<p>
							Pour ajouter des lieux pensez à vérifier votre
							adresse mail est devenez :<br />
							<span>un pionier WeaselMap !</span> <br />
							<button
								className="resendEmail"
								onClick={() => resendVerificationEmail()}
							>
								Renvoyer le mail de confirmation
							</button>
						</p>
					)}
					{/* Afficher le bouton pour ouvrir le formulaire d'ajout */}
					{!isAnonymous && isEmailVerified && (
						<button
							type="button"
							onClick={openModalAddForm}
							className="add-place-button"
						>
							<span className="material-symbols-outlined">
								add_location_alt
							</span>
							<span>Ajouter un lieu</span>
						</button>
					)}
					{/* Modal pour le formulaire d'ajout de lieu */}
					<Modal
						isOpen={isModalAddFormOpen}
						onClose={closeModalAddForm}
						className={'addPlaceForm__modal'}
					>
						<AddPlaceForm
							onClose={closeModalAddForm}
							userStatus={userStatus}
							userEmail={userEmail}
							userId={userId}
							existingData={null} // Pas de données existantes pour une création
							mode={'creation'} // Mode création
						/>
					</Modal>
				</footer>
			)}
		</>
	)
}

PlaceCard.propTypes = {
	className: PropTypes.string,
	data: PropTypes.array.isRequired,
	selectedPlaceId: PropTypes.string,
	onSeeMore: PropTypes.func,
	onSeeOnMap: PropTypes.func,
	userPreferences: PropTypes.object
}

export default PlaceCard
