import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	sendEmailVerification,
	sendPasswordResetEmail
} from 'firebase/auth'
import { auth } from './firebaseConfig'

// Inscription de l'utilisateur avec email de vérification
const signUp = async (email, password) => {
	const userCredential = await createUserWithEmailAndPassword(
		auth,
		email,
		password
	)
	await sendEmailVerification(userCredential.user) // Envoi de l'email de validation
	return userCredential
}

// Connexion de l'utilisateur
const logIn = (email, password) => {
	return signInWithEmailAndPassword(auth, email, password)
}

// Déconnexion de l'utilisateur
const logOut = () => {
	return signOut(auth)
}

// Envoyer un email de récupération de mot de passe
const resetPassword = (email) => {
	return sendPasswordResetEmail(auth, email)
}

export { signUp, logIn, logOut, resetPassword }
