import { useEffect, useRef, useState, useCallback } from 'react'
import Map, { Source, Layer, AttributionControl } from 'react-map-gl'
import MarkerMap from '../Map-Marker'

const MapMain = ({
	data,
	setActiveMenu,
	setSelectedPlaceId,
	highlightedPlaceId,
	onBoundsChange,
	userLocation,
	centerOnPlaceTrigger
}) => {
	const mapRef = useRef(null)
	const [currentZoom, setCurrentZoom] = useState(12)
	const [attributionConfig, setAttributionConfig] = useState({
		position: 'bottom-right',
		compact: false
	})

	const zoomThreshold = 12.5 // Seuil de zoom pour afficher les marqueurs individuels

	// Position par défaut (Le Quesnoy)
	const defaultLocation = {
		userLat: 50.247342,
		userLon: 3.63645
	}

	// Vérifiez la largeur de l'écran et mettez à jour la position de l'attribution
	useEffect(() => {
		const updateAttributionConfig = () => {
			if (window.innerWidth < 768) {
				setAttributionConfig({ position: 'top-left', compact: true })
			} else {
				setAttributionConfig({
					position: 'bottom-right',
					compact: false
				})
			}
		}

		// configuration initiale
		updateAttributionConfig()

		// Listener pour la redimenssion de l'écran
		window.addEventListener('resize', updateAttributionConfig)

		//Nettoyage au démontage
		return () =>
			window.removeEventListener('resize', updateAttributionConfig)
	}, [])

	// // Empêcher la rotation de la carte - DOESN'T WORK
	// useEffect(() => {
	// 	if (mapRef.current) {
	// 		const map = mapRef.current.getMap()

	// 		// Désactiver les interactions liées à la rotation
	// 		map.dragRotate.disable()
	// 		map.touchZoomRotate.disableRotation()
	// 	}
	// }, [])

	// Fonction pour centrer la carte sur un lieu
	const centerOnPlace = (longitude, latitude) => {
		if (mapRef.current && longitude && latitude) {
			const map = mapRef.current.getMap()

			// Vérifiez la largeur de l'écran
			const isSmallScreen = window.innerWidth < 768

			// Appliquez un décalage vertical si l'écran est petit
			const offset = isSmallScreen ? [0, -175] : [0, 0]

			map.flyTo({
				center: [longitude, latitude],
				zoom: 12.5,
				speed: 1.2,
				bearing: 0,
				essential: true,
				offset: offset // Appliquez le décalage
			})
		}
	}

	// Récupérer les bounds actuels de la carte et les transmettre au parent
	const handleBoundsChange = useCallback(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			const bounds = map.getBounds()
			const boundsData = {
				northEast: bounds.getNorthEast().toArray(),
				southWest: bounds.getSouthWest().toArray()
			}
			onBoundsChange(boundsData)
		}
	}, [mapRef, onBoundsChange])

	useEffect(() => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()

			// Call handleBoundsChange initially and on move end
			handleBoundsChange()
			map.on('moveend', handleBoundsChange)

			// Clean up
			return () => {
				map.off('moveend', handleBoundsChange)
			}
		}
	}, [handleBoundsChange])

	// Centrer la carte sur un lieu lorsque `highlightedPlaceId` change
	useEffect(() => {
		if (highlightedPlaceId) {
			const placeToCenter = data.find(
				(place) => place.id === highlightedPlaceId
			)
			if (placeToCenter) {
				const [longitude, latitude] = placeToCenter.geometry.coordinates
				centerOnPlace(longitude, latitude)
			}
		}
	}, [centerOnPlaceTrigger, highlightedPlaceId, data])

	// Déplacer la carte vers `userLocation` lorsque celle-ci est obtenue
	useEffect(() => {
		if (userLocation && userLocation.userLon && userLocation.userLat) {
			centerOnPlace(userLocation.userLon, userLocation.userLat)
		}
	}, [userLocation])

	// Mise à jour du zoom actuel
	const handleZoomChange = () => {
		if (mapRef.current) {
			const map = mapRef.current.getMap()
			setCurrentZoom(map.getZoom())
		}
	}

	// Utiliser la position de l'utilisateur si disponible, sinon la position par défaut
	const mapViewLocation =
		userLocation && userLocation.userLon && userLocation.userLat
			? userLocation
			: defaultLocation

	// Configuration des couches pour le clustering
	const clusterLayer = {
		id: 'clusters',
		type: 'circle',
		source: 'places',
		filter: ['>=', ['get', 'point_count'], 1],
		paint: {
			'circle-color': '#d8b458',
			'circle-radius': [
				'step',
				['get', 'point_count'],
				20,
				100,
				30,
				750,
				40
			],
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff'
		}
	}

	const clusterCountLayer = {
		id: 'cluster-count',
		type: 'symbol',
		source: 'places',
		filter: ['>=', ['get', 'point_count'], 1],
		layout: {
			'text-field': '{point_count_abbreviated}',
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-size': 12
		}
	}

	const unclusteredPointLayer = {
		id: 'unclustered-point',
		type: 'circle',
		source: 'places',
		filter: ['!', ['has', 'point_count']],
		paint: {
			'circle-color': '#d8b458',
			'circle-radius': 10,
			'circle-stroke-width': 2,
			'circle-stroke-color': '#fff'
		}
	}

	// Gérer le clic sur un cluster
	const handleClusterClick = (event) => {
		const map = mapRef.current.getMap()

		// Vérifier si la couche 'clusters' existe avant de faire la requête
		if (map.getLayer('clusters') || map.getLayer('unclustered-point')) {
			const features = map.queryRenderedFeatures(event.point, {
				layers: ['clusters', 'unclustered-point']
			})

			if (features.length) {
				const feature = features[0]

				// Si le clic est sur un cluster
				if (feature.layer.id === 'clusters') {
					const clusterId = feature.properties.cluster_id
					map.getSource('places').getClusterExpansionZoom(
						clusterId,
						(err, zoom) => {
							if (err) return
							const [longitude, latitude] =
								feature.geometry.coordinates
							map.easeTo({
								center: [longitude, latitude],
								zoom: 12.5,
								duration: 500
							})
						}
					)
				}

				// Si le clic est sur un point isolé
				if (feature.layer.id === 'unclustered-point') {
					const [longitude, latitude] = feature.geometry.coordinates
					map.easeTo({
						center: [longitude, latitude],
						zoom: 12.5,
						duration: 500
					})
				}
			}
		} else {
			console.warn("La couche 'clusters' n'est pas encore disponible.")
		}
	}

	return (
		<div className="mapContainer">
			<Map
				ref={mapRef}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				mapStyle="mapbox://styles/weaselmap/cl0c7riwf000d14tamjgzvqrg"
				logoPosition="top-right"
				addControl="top-left"
				attributionControl={false}
				initialViewState={{
					longitude: mapViewLocation.userLon,
					latitude: mapViewLocation.userLat,
					zoom: 12.5,
					bearing: 0
				}}
				dragRotate={false} // Désactive la rotation via un glissement
				touchRotate={false} // Désactive la rotation via les gestes tactiles
				minZoom={10}
				maxZoom={17}
				onMoveEnd={() => {
					handleBoundsChange()
					handleZoomChange()
				}}
				onClick={handleClusterClick}
			>
				<AttributionControl
					position={attributionConfig.position}
					compact={attributionConfig.compact}
				/>
				<Source
					id="places"
					type="geojson"
					data={{
						type: 'FeatureCollection',
						features: data
					}}
					cluster={true}
					clusterMaxZoom={13}
					clusterRadius={50}
				>
					{currentZoom < zoomThreshold && (
						<>
							<Layer {...clusterLayer} />
							<Layer {...clusterCountLayer} />
							<Layer {...unclusteredPointLayer} />
						</>
					)}
				</Source>

				{/* Affichage des marqueurs individuels uniquement si le zoom est supérieur au seuil */}
				{currentZoom >= zoomThreshold &&
					data.map((place) => {
						const [longitude, latitude] = place.geometry.coordinates
						return (
							<MarkerMap
								key={place.id}
								latitude={latitude}
								longitude={longitude}
							>
								<div
									className={`marker ${
										highlightedPlaceId === place.id
											? 'highlighted-marker'
											: ''
									}`}
									data-title={place.title}
									onClick={() => {
										setSelectedPlaceId(place.id)
										setActiveMenu('menu2')
									}}
								>
									<span className="material-symbols-outlined">
										location_on
									</span>
								</div>
							</MarkerMap>
						)
					})}
			</Map>
		</div>
	)
}

export default MapMain
