import { useNavigate } from 'react-router-dom'

const BackButton = ({ children, type, className }) => {
	const navigate = useNavigate()

	const handleBackAuthhoice = () => {
		navigate('/auth-choice')
	}

	return (
		<button
			onClick={handleBackAuthhoice}
			className={`backButton ${className}`}
			type={type}
		>
			<span className="material-symbols-outlined spanBackButton">
				arrow_back
			</span>
			{children}
		</button>
	)
}

export default BackButton
