import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN
const geocodingClient = mbxGeocoding({ accessToken: mapboxToken })

// Fonction pour géocoder une ville et retourner les coordonnées
export const geocodeCity = async (city) => {
	try {
		const response = await geocodingClient
			.forwardGeocode({
				query: city,
				limit: 1
			})
			.send()

		const match = response.body.features[0]
		if (match) {
			const { center } = match
			return {
				latitude: center[1],
				longitude: center[0]
			}
		} else {
			throw new Error('City not found')
		}
	} catch (error) {
		console.error('Error geocoding city:', error)
		throw error
	}
}

// Fonction pour obtenir la position GPS de l'utilisateur
export const getUserLocation = () => {
	return new Promise((resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords
					resolve({ latitude, longitude })
				},
				(error) => {
					console.error('Error getting location:', error)
					reject(error)
				},
				{ enableHighAccuracy: true, timeout: 10000 }
			)
		} else {
			reject(new Error('Geolocation is not supported by this browser.'))
		}
	})
}
