import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ref, getDownloadURL } from 'firebase/storage'
import { storage } from '../../../services/firebaseConfig'

// Components
import Modal from '../Modal'

function Carrousel({
	title,
	pictures,
	id,
	reduceView,
	modal,
	initialImgIndex = 0
}) {
	const [imgIndex, setImgIndex] = useState(initialImgIndex)
	const [imageUrls, setImageUrls] = useState([])
	const [activeModalId, setActiveModalId] = useState(null)

	const openModal = (id) => setActiveModalId(id)
	const closeModal = () => setActiveModalId(null)

	// Génère une clé unique pour chaque carrousel en utilisant l'id
	const storageKey = id

	useEffect(() => {
		const fetchImageUrls = async () => {
			// Tentative de récupération des URLs spécifiques au carrousel depuis le Local Storage
			const cachedUrls = localStorage.getItem(storageKey)

			if (cachedUrls) {
				// Si les URLs sont trouvées, on les utilise directement
				setImageUrls(JSON.parse(cachedUrls))
			} else {
				// Sinon, on les récupère depuis Firebase Storage
				const urls = await Promise.all(
					Object.values(pictures).map(async (picture) => {
						if (picture.startsWith('gs://')) {
							const gsReference = ref(
								storage,
								picture.replace(
									'gs://weaselmap-app.appspot.com/',
									''
								)
							)
							return await getDownloadURL(gsReference)
						} else {
							return picture
						}
					})
				)
				// Mise à jour de l'état avec les URLs obtenues
				setImageUrls(urls)
				// Stockage des URLs dans le Local Storage sous une clé unique pour ce carrousel
				localStorage.setItem(storageKey, JSON.stringify(urls))
			}
		}

		fetchImageUrls().catch(console.error)
	}, [pictures, storageKey]) // Ajout de storageKey dans les dépendances pour que l'effet se déclenche si l'id change

	function clickG(e) {
		e.preventDefault()
		let newIndex = imgIndex - 1
		if (newIndex === -1) {
			newIndex = imageUrls.length - 1
		}
		setImgIndex(newIndex)
	}

	function clickD(e) {
		e.preventDefault()
		let newIndex = imgIndex + 1
		if (newIndex === imageUrls.length) {
			newIndex = 0
		}
		setImgIndex(newIndex)
	}

	// Fonction pour effacer le cache pour ce carrousel spécifique
	// function clearCache() {
	// 	localStorage.removeItem(storageKey)
	// 	setImageUrls([]) // Réinitialise les URLs des images
	// }

	// Si reduceView est true, on affiche seulement les deux premières images côte à côte
	if (reduceView) {
		const firstTwoImages = imageUrls.slice(0, 2)
		return (
			<div className="carrousel-reduceview">
				<div className="carrousel__inner-reduceview">
					{firstTwoImages.map((url, index) => (
						<img
							key={index}
							src={url}
							alt={`${title} vue n° ${index + 1}`}
							className={`reduceview-img ${
								imageUrls.length === 1 ? ' onePicture' : ''
							}`}
						/>
					))}
				</div>
				{/* <button className="devButton" onClick={clearCache}>
					Effacer le cache
				</button> */}
			</div>
		)
	}

	return (
		<div className="carrousel">
			<div
				className="carrousel__blur"
				style={{
					backgroundImage: `url(${imageUrls[imgIndex]})`
				}}
			></div>
			<div className="carrousel__content">
				{imageUrls.length > 1 && (
					<>
						<button
							className="carrousel__button left"
							onClick={clickG}
						>
							<span className="material-symbols-outlined">
								chevron_left
							</span>
						</button>
						<span className="carrousel__index">
							{imgIndex + 1}/{imageUrls.length}
						</span>
					</>
				)}
				{!modal ? (
					<button
						onClick={() => openModal(id)}
						className="carrousel__buttonModal"
					>
						<img
							src={imageUrls[imgIndex]}
							alt={`${title} vue n° ${imgIndex + 1}`}
							className="carrousel__img"
						/>
					</button>
				) : (
					<>
						<img
							src={imageUrls[imgIndex]}
							alt={`${title} vue n° ${imgIndex + 1}`}
							className="carrousel__img"
						/>
					</>
				)}

				{imageUrls.length > 1 && (
					<>
						<button
							className="carrousel__button right"
							onClick={clickD}
						>
							<span className="material-symbols-outlined">
								chevron_right
							</span>
						</button>
					</>
				)}
			</div>
			{/* <button className="devButton" onClick={clearCache}>
				Effacer le cache
			</button> */}

			<Modal isOpen={activeModalId === id} onClose={closeModal}>
				<div className={'carrousel__modal'}>
					<Carrousel
						title={title}
						pictures={pictures}
						id={id}
						reduceView={false}
						modal={true}
						initialImgIndex={imgIndex}
					/>
				</div>
			</Modal>
		</div>
	)
}

Carrousel.propTypes = {
	title: PropTypes.string.isRequired,
	// pictures: PropTypes.objectOf(PropTypes.string).isRequired,
	id: PropTypes.string.isRequired, // Ajout de l'id comme clé unique pour le cache
	reduceView: PropTypes.bool // Ajout de la prop reduceView
}

export default Carrousel
