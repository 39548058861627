import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from './firebaseConfig'
import { onAuthStateChanged } from 'firebase/auth'

const RedirectIfAuthenticated = ({ children }) => {
	const navigate = useNavigate()

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user && !user.isAnonymous) {
				navigate('/') // Redirection vers la page d'accueil si l'utilisateur est connecté avec un email
			}
		})

		return () => unsubscribe()
	}, [navigate])

	return children
}

export default RedirectIfAuthenticated
