import { useEffect, useRef, useState } from 'react'
import Map from 'react-map-gl'
import PropTypes from 'prop-types'

import MarkerMap from '../Map-Marker'

const MiniMap = ({ latitude = 0, longitude = 0, onPositionChange }) => {
	const mapRef = useRef(null)
	const [markerPosition, setMarkerPosition] = useState({
		latitude: latitude,
		longitude: longitude
	})

	// Mettre à jour la position du marqueur lorsque les props changent
	useEffect(() => {
		if (latitude !== null && longitude !== null) {
			setMarkerPosition({ latitude, longitude })
		}
	}, [latitude, longitude])

	// Fonction pour déplacer la carte sur le marqueur initial
	useEffect(() => {
		if (
			mapRef.current &&
			markerPosition.latitude &&
			markerPosition.longitude
		) {
			const map = mapRef.current.getMap()
			map.flyTo({
				center: [markerPosition.longitude, markerPosition.latitude],
				speed: 1.2,
				essential: true
			})
		}
	}, [markerPosition])

	// Gestion du double-clic sur la carte pour repositionner le marqueur
	const handleDoubleClick = (event) => {
		event.preventDefault()
		const { lngLat } = event
		const newLatitude = lngLat.lat
		const newLongitude = lngLat.lng

		// Mettre à jour la position du marqueur
		setMarkerPosition({
			latitude: newLatitude,
			longitude: newLongitude
		})

		// Appeler la fonction de rappel pour renvoyer la position mise à jour
		if (onPositionChange) {
			onPositionChange(newLatitude, newLongitude)
		}
	}

	return (
		<div className="miniMapContainer">
			<Map
				ref={mapRef}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
				mapStyle="mapbox://styles/weaselmap/cl0c7riwf000d14tamjgzvqrg"
				initialViewState={{
					longitude: markerPosition.longitude || 0,
					latitude: markerPosition.latitude || 0,
					zoom: 15
				}}
				doubleClickZoom={false}
				onDblClick={handleDoubleClick}
			>
				{/* Marqueur - Affiche le marqueur uniquement si les coordonnées sont valides */}
				{markerPosition.latitude && markerPosition.longitude && (
					<MarkerMap
						latitude={markerPosition.latitude}
						longitude={markerPosition.longitude}
					>
						<div className="marker">
							<span className="material-symbols-outlined">
								location_on
							</span>
						</div>
					</MarkerMap>
				)}
			</Map>
		</div>
	)
}

MiniMap.propTypes = {
	latitude: PropTypes.number,
	longitude: PropTypes.number,
	onPositionChange: PropTypes.func.isRequired
}

export default MiniMap
