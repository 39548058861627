///Elements
import gifWeasel from '../../../assets/gifWeasel.gif'

const Loading = () => {
	return (
		<div className="loading">
			<h1>WeaselMap</h1>
			{/* <div className="loading__info">
				<p className="text">
					N'oubliez pas d'activer (ou non) la localisation GPS
				</p>
				<span className="material-symbols-outlined icon">explore</span>
			</div> */}

			<div className="logo">
				<img className="gifLogo" src={gifWeasel} alt=" " />
			</div>

			<div className="txtLoading">
				<span className="txt">Chargement</span>
				<span className="points">.</span>
				<span className="points">.</span>
				<span className="points">.</span>
			</div>
		</div>
	)
}

export default Loading
