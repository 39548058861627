///////////////Librairies///////////////
import React from 'react'
import ReactDOM from 'react-dom/client'

///////////////Components///////////////
import App from './App'

///////////////Style///////////////
import './index.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

///////////////Tools///////////////
import * as serviceWorkerRegistration from './services/serviceWorkerRegistration'
import reportWebVitals from './services/reportWebVitals'

///////////////Logique///////////////
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)

//
//
//
//
//
//
//
//
//
///////////////Test///////////////
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
