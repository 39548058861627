import { Marker } from 'react-map-gl'

const MarkerMap = ({ latitude, longitude, children }) => {
	const lat = parseFloat(latitude)
	const lng = parseFloat(longitude)

	// Vérifier si les valeurs sont valides
	if (
		isNaN(lat) ||
		isNaN(lng) ||
		lat < -90 ||
		lat > 90 ||
		lng < -180 ||
		lng > 180
	) {
		console.error('Valeurs de latitude ou longitude invalides :', lat, lng)
		return null
	}

	return (
		<Marker
			latitude={lat}
			longitude={lng}
			anchor="bottom"
			pitchAlignment="viewport"
			rotationAlignment="map"
		>
			{children}
		</Marker>
	)
}

export default MarkerMap
