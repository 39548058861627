import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { auth } from '../../../services/firebaseConfig'
import {
	updatePassword,
	updateEmail,
	signOut,
	reauthenticateWithCredential,
	EmailAuthProvider,
	getAuth,
	onAuthStateChanged
} from 'firebase/auth'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { sendEmailVerification } from 'firebase/auth'
import { firestore } from '../../../services/firebaseConfig'

//////Component/////////
import CreateAccountButton from '../Btn-CreateAccount'
import ReportButton from '../Btn-Report'

const SettingsUser = () => {
	const [currentPassword, setCurrentPassword] = useState('') // Mot de passe actuel
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [newEmail, setNewEmail] = useState('') // Nouvelle adresse e-mail
	const [acceptContact, setAcceptContact] = useState(null) // Préférence de contact
	const [emailMessage, setEmailMessage] = useState('') // Message pour le formulaire e-mail
	const [emailError, setEmailError] = useState('') // Erreur pour le formulaire e-mail
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [passwordMessage, setPasswordMessage] = useState('') // Message pour le formulaire mot de passe
	const [passwordError, setPasswordError] = useState('') // Erreur pour le formulaire mot de passe
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isEmailUser, setIsEmailUser] = useState(false) // Pour vérifier si l'utilisateur est connecté avec un email
	const navigate = useNavigate()

	// Vérifier l'état de connexion de l'utilisateur
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
			if (user) {
				setIsAuthenticated(true)
				setIsEmailUser(!user.isAnonymous)

				// Vérifier si la préférence de contact existe déjà dans le localStorage
				const savedPreference = localStorage.getItem('acceptContact')
				if (savedPreference !== null) {
					setAcceptContact(savedPreference === 'true') // Convertir en booléen
				} else {
					// Sinon, récupérer la préférence depuis Firestore
					const userDoc = await getDoc(
						doc(firestore, 'users', user.uid)
					)
					if (userDoc.exists()) {
						const userData = userDoc.data()
						setAcceptContact(userData.acceptContact ?? null)
						localStorage.setItem(
							'acceptContact',
							userData.acceptContact ?? null
						)
					}
				}
			} else {
				navigate('/auth-choice')
			}
		})
		return () => unsubscribe()
	}, [navigate])

	// Fonction de réauthentification avec le mot de passe actuel
	const reauthenticate = async () => {
		const user = auth.currentUser
		const credential = EmailAuthProvider.credential(
			user.email,
			currentPassword
		)

		try {
			await reauthenticateWithCredential(user, credential)
			return true // Réauthentification réussie
		} catch (error) {
			setPasswordError('Mot de passe actuel incorrect.')
			return false // Réauthentification échouée
		}
	}

	// Changer le mot de passe après réauthentification
	const handlePasswordChange = async (e) => {
		e.preventDefault()
		setPasswordMessage('') // Nettoyer le message précédent
		setPasswordError('') // Nettoyer l'erreur précédente

		if (newPassword !== confirmNewPassword) {
			setPasswordError('Les nouveaux mots de passe ne correspondent pas.')
			return
		}

		const reauthenticated = await reauthenticate()
		if (reauthenticated && newPassword) {
			const user = auth.currentUser

			try {
				await updatePassword(user, newPassword)
				setPasswordMessage('Mot de passe mis à jour avec succès.')
				setCurrentPassword('')
				setNewPassword('')
				setConfirmNewPassword('')
			} catch (err) {
				setPasswordError(err.message)
			}
		}
	}

	// Changer l'adresse e-mail après réauthentification
	const handleEmailChange = async (e) => {
		e.preventDefault()
		setEmailMessage('') // Nettoyer le message précédent
		setEmailError('') // Nettoyer l'erreur précédente

		// Vérifier si le mot de passe actuel est fourni
		if (!currentPassword) {
			setEmailError(
				'Veuillez entrer votre mot de passe actuel pour confirmer le changement d’adresse e-mail.'
			)
			return
		}

		const reauthenticated = await reauthenticate()
		if (reauthenticated && newEmail) {
			const user = auth.currentUser

			try {
				// Étape 1 : Envoyer une demande de vérification à la nouvelle adresse
				await sendEmailVerification(user)
				setEmailMessage(
					`Un e-mail de vérification a été envoyé à ${newEmail}. Veuillez vérifier votre e-mail avant de continuer.`
				)

				// Étape 2 : Permettre la mise à jour de l'e-mail une fois vérifiée
				await updateEmail(user, newEmail)

				// Mettre à jour Firestore avec la nouvelle adresse e-mail
				const userDocRef = doc(firestore, 'users', user.uid)
				await updateDoc(userDocRef, { email: newEmail })

				setEmailMessage('Adresse e-mail mise à jour avec succès.')
				setNewEmail('')
				setCurrentPassword('') // Réinitialiser le mot de passe actuel
			} catch (err) {
				setEmailError(err.message)
			}
		}
	}

	// Mettre à jour la préférence de contact
	const handleContactPreferenceChange = async (preference) => {
		setAcceptContact(preference)
		localStorage.setItem('acceptContact', preference)

		const user = auth.currentUser
		if (user) {
			try {
				// Mettre à jour dans Firestore uniquement si nécessaire
				const userDoc = await getDoc(doc(firestore, 'users', user.uid))
				if (userDoc.exists()) {
					const userData = userDoc.data()
					if (userData.acceptContact !== preference) {
						await updateDoc(doc(firestore, 'users', user.uid), {
							acceptContact: preference
						})
					}
				}
				setMessage(
					preference
						? 'Vous acceptez de recevoir des e-mails de WeaselMap.'
						: 'Vous refusez de recevoir des e-mails de WeaselMap.'
				)
			} catch (err) {
				setError('Erreur lors de la mise à jour de la préférence.')
			}
		}
	}

	if (!isAuthenticated || !isEmailUser) {
		return (
			<>
				<div className="settingsUser">
					<h3>
						Vous devez être connecté pour accéder aux paramètres de
						gestion du compte
					</h3>
					<CreateAccountButton className="settingsUser__createAccount" />
				</div>
				<footer className="settingsUser__footer">
					<Link to={'/WeaselMap-CGU'}>
						Conditions générales d'utilisation
					</Link>
					<ReportButton type={'contact'} className={'-contact'} />
				</footer>
			</>
		)
	}

	return (
		<>
			<div className="settingsUser">
				<h3>Paramètres utilisateur</h3>

				{/* Changer l'adresse e-mail */}
				<form className="settingsUser__majEmailForm">
					<h4>Changer l'adresse e-mail</h4>
					<input
						type="password"
						placeholder="Mot de passe actuel"
						value={currentPassword}
						onChange={(e) => {
							setEmailError('')
							setEmailMessage('')
							setCurrentPassword(e.target.value)
						}}
						required
					/>
					<input
						type="email"
						placeholder="Nouvelle adresse e-mail"
						value={newEmail}
						onChange={(e) => {
							setEmailError('')
							setEmailMessage('')
							setNewEmail(e.target.value)
						}}
						required
					/>
					<button type="submit" onClick={handleEmailChange}>
						Mettre à jour
					</button>
					{emailMessage && (
						<p className="message success">{emailMessage}</p>
					)}
					{emailError && (
						<p className="message error">{emailError}</p>
					)}
				</form>

				{/* Changer le mot de passe */}
				<form className="settingsUser__majPasswordForm">
					<h4>Changer le mot de passe</h4>
					<input
						type="password"
						placeholder="Mot de passe actuel"
						value={currentPassword}
						onChange={(e) => {
							setPasswordError('')
							setPasswordMessage('')
							setCurrentPassword(e.target.value)
						}}
					/>
					<input
						type="password"
						placeholder="Nouveau mot de passe"
						value={newPassword}
						autoComplete="new-password"
						onChange={(e) => {
							setPasswordError('')
							setPasswordMessage('')
							setNewPassword(e.target.value)
						}}
					/>
					<input
						type="password"
						placeholder="Confirmer le mot de passe"
						value={confirmNewPassword}
						autoComplete="new-password"
						onChange={(e) => {
							setPasswordError('')
							setPasswordMessage('')
							setConfirmNewPassword(e.target.value)
						}}
					/>
					<button type="submit" onClick={handlePasswordChange}>
						Mettre à jour
					</button>
					{passwordMessage && (
						<p className="message success">{passwordMessage}</p>
					)}
					{passwordError && (
						<p className="message error">{passwordError}</p>
					)}
				</form>

				{/* Accepter/refuser d'être contacté */}
				<form className="settingsUser__contactPreference">
					<h4>
						Acceptez-vous de recevoir des mails de l'équipe
						WeaselMap ?
					</h4>
					<div className="settingsUser__contactPreference__tick">
						<label htmlFor="contactYes">
							<input
								type="radio"
								id="contactYes"
								name="contactPreference"
								checked={acceptContact === true}
								onChange={() =>
									handleContactPreferenceChange(true)
								}
							/>
							Oui
						</label>
						<label htmlFor="contactNo">
							<input
								type="radio"
								id="contactNo"
								name="contactPreference"
								checked={acceptContact === false}
								onChange={() =>
									handleContactPreferenceChange(false)
								}
							/>
							Non
						</label>
					</div>
				</form>

				{/* Affichage des messages */}
				{message && <p className="message success">{message}</p>}
				{error && <p className="message error">{error}</p>}
			</div>

			<button
				className="settingsUser__logout"
				onClick={() => signOut(auth)}
			>
				Se déconnecter
			</button>

			<footer className="settingsUser__footer">
				<Link to={'/WeaselMap-CGU'}>
					Conditions générales d'utilisation
				</Link>
				<ReportButton type={'contact'} className={'-contact'} />
			</footer>
		</>
	)
}

export default SettingsUser
