import React, { useState, useRef, useEffect } from 'react'

///////////////Components///////////////
import MenuOpen from '../Menu_open/MenuOpen'
import PlaceCard from '../../Objects/Place_card'
import SettingsUser from '../../Objects/SettingsUser'
import FavoritesList from '../../Objects/FavoritesList'
import TagsBox from '../../Objects/TagsBox'
import CitySearch from '../../Objects/CitySearch'

///////////////Objets///////////////
import weaselIconBrown from '../../../assets/beletteMarron256.png'
import weaselIconBlue from '../../../assets/beletteBleu256.png'
import ToggleFilters from '../../Objects/Btn-ToggleFilters'
import UserPresentation from '../../Objects/UserPresentation'

const MenuNav = ({
	data,
	userId,
	userStatus,
	userEmail,
	userPseudo,
	userLevel,
	isAnonymous,
	userPreferences,
	isEmailVerified,
	resendVerificationEmail,
	onPreferencesUpdate,
	filtersEnabled,
	setFiltersEnabled,
	activeMenu,
	setActiveMenu,
	selectedPlaceId,
	setSelectedPlaceId,
	onCitySearch,
	userLocation
}) => {
	const [isScrolled, setIsScrolled] = useState(false)
	const [maxMenu, setMaxMenu] = useState(false)
	const [touchStartY, setTouchStartY] = useState(null)
	const touchThreshold = 100 // Pixels pour détecter le glissement
	const [randomPlaceId, setRandomPlaceId] = useState(null)
	const menu1Ref = useRef(null)
	const menu2Ref = useRef(null)

	const handleMenuClick = (menu) => {
		setActiveMenu((prevMenu) => (prevMenu === menu ? null : menu))
	}

	const handleMaximizeToggle = () => {
		setMaxMenu((prevMaxMenu) => !prevMaxMenu)
	}

	// Gestionnaires d'événements tactiles pour le geste de glissement vers le bas
	const handleTouchStart = (e) => {
		setTouchStartY(e.touches[0].clientY)
	}

	const handleTouchMove = (e) => {
		if (touchStartY === null) return

		const touchY = e.touches[0].clientY
		const deltaY = touchY - touchStartY

		if (deltaY > touchThreshold) {
			// L'utilisateur a glissé vers le bas
			setActiveMenu(null)
			setMaxMenu(false) // Réduire le menu
			setTouchStartY(null)
			e.preventDefault() // Empêche le rafraîchissement de la page
		} else if (deltaY < -touchThreshold) {
			// L'utilisateur a glissé vers le haut
			setMaxMenu(true) // Maximiser le menu
			setTouchStartY(null)
		}
	}

	const handleTouchEnd = () => {
		setTouchStartY(null)
	}

	// Défilement vers le haut le menu1
	const handleScrollTop = () => {
		if (menu1Ref.current) {
			menu1Ref.current.scrollTo({
				top: 0,
				behavior: 'smooth'
			})
		}
	}

	const handleScroll = () => {
		if (menu1Ref.current) {
			// Si le scroll est en haut, le bouton sera caché
			setIsScrolled(menu1Ref.current.scrollTop > 500)
		}
	}

	useEffect(() => {
		if (menu1Ref.current) {
			const menuElement = menu1Ref.current
			menuElement.addEventListener('scroll', handleScroll)
			// Nettoyage lors du démontage du composant
			return () => {
				menuElement.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	useEffect(() => {
		if (activeMenu === 'menu2' && menu2Ref.current) {
			// Réinitialiser le scroll du menu 2
			menu2Ref.current.scrollTop = 0
		}
	}, [activeMenu, selectedPlaceId])

	useEffect(() => {
		if (!selectedPlaceId && data && data.length > 0) {
			const randomIndex = Math.floor(Math.random() * data.length)
			setRandomPlaceId(data[randomIndex].id)
		}
	}, [selectedPlaceId, data, userLocation])

	return (
		<div className={`menuNav ${activeMenu ? 'active' : ''}`}>
			<nav>
				<button
					className={`menuNav__buttonMenu ${
						activeMenu === 'menu1' ? 'button-active' : ''
					}`}
					onClick={() => handleMenuClick('menu1')}
				>
					<span className="material-symbols-outlined">menu</span>
				</button>

				<button
					className={`menuNav__buttonWeasel ${
						activeMenu === 'menu2' ? 'button-active' : ''
					}`}
					onClick={() => handleMenuClick('menu2')}
				>
					<span className="menuNav__buttonWeasel__span">
						<img
							className="menuNav__buttonWeasel__img"
							src={
								activeMenu === 'menu2'
									? weaselIconBlue
									: weaselIconBrown
							}
							alt=""
						/>
					</span>
				</button>

				<button
					className={`menuNav__buttonSettings ${
						activeMenu === 'menu3' ? 'button-active' : ''
					}`}
					onClick={() => handleMenuClick('menu3')}
				>
					<span className="material-symbols-outlined">settings</span>
				</button>
			</nav>

			{/* Menu 1 */}
			<div
				ref={menu1Ref}
				className={`menuNav__containeMenu ${
					activeMenu === 'menu1' ? 'containeMenu-active' : ''
				} ${maxMenu ? 'maximized' : ''}`}
			>
				<button
					className="menuNav__containeMenu__button"
					onClick={handleMaximizeToggle}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
				>
					<span className="material-symbols-outlined">maximize</span>
				</button>
				{activeMenu === 'menu1' && (
					<MenuOpen
						title={'Autour de chez vous'}
						variant={'menuOpen-aroundYou'}
					>
						<CitySearch onCitySelect={onCitySearch} />
						<p className="menuOpen__content__message">
							Retrouvez ici des idées de sorties proches de chez
							vous. Cliquez sur l'un des lieux pour le découvrir.
						</p>
						<ToggleFilters
							filtersEnabled={filtersEnabled}
							setFiltersEnabled={setFiltersEnabled} // Passer la fonction pour mettre à jour l'état
							className={'toggleFiltersMenu'}
						/>
						<PlaceCard
							className={'placeCard-aroundYou'}
							data={data}
							isEmailVerified={isEmailVerified}
							resendVerificationEmail={resendVerificationEmail}
							userStatus={userStatus}
							userId={userId}
							isAnonymous={isAnonymous}
							onSeeMore={setSelectedPlaceId}
							userPreferences={userPreferences}
						/>
						{isScrolled && (
							<button
								className="menuNav__containeMenu__topBtn"
								onClick={handleScrollTop}
								title="Remonter"
							>
								<span className="material-symbols-outlined">
									arrow_upward_alt
								</span>
							</button>
						)}
					</MenuOpen>
				)}
			</div>

			{/* Menu 2 */}
			<div
				ref={menu2Ref}
				className={`menuNav__containeMenu ${
					activeMenu === 'menu2' ? 'containeMenu-active' : ''
				} ${maxMenu ? 'maximized' : ''}`}
			>
				<button
					className="menuNav__containeMenu__button"
					onClick={handleMaximizeToggle}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
				>
					<span className="material-symbols-outlined">maximize</span>
				</button>
				{activeMenu === 'menu2' &&
					(selectedPlaceId || randomPlaceId) && (
						<MenuOpen
							title={'Votre prochaine sortie ?'}
							variant={'menuOpen-nextAdventure'}
							handleMenuClick={handleMenuClick}
						>
							<PlaceCard
								className={'placeCard-nextOuting'}
								data={data}
								selectedPlaceId={
									selectedPlaceId || randomPlaceId
								}
								userPreferences={userPreferences}
								userStatus={userStatus}
								userEmail={userEmail}
								isEmailVerified={isEmailVerified}
								userId={userId}
								isAnonymous={isAnonymous}
								onSeeOnMap={setSelectedPlaceId}
							/>
							<FavoritesList
								userId={userId}
								userPreferences={userPreferences}
								userStatus={userStatus}
								isEmailVerified={isEmailVerified}
								userEmail={userEmail}
								isAnonymous={isAnonymous}
								setSelectedPlaceId={setSelectedPlaceId}
								onSeeMore={setSelectedPlaceId}
							/>
						</MenuOpen>
					)}
			</div>

			{/* Menu 3 */}
			<div
				className={`menuNav__containeMenu ${
					activeMenu === 'menu3' ? 'containeMenu-active' : ''
				} ${maxMenu ? 'maximized' : ''}`}
			>
				<button
					className="menuNav__containeMenu__button"
					onClick={handleMaximizeToggle}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
				>
					<span className="material-symbols-outlined">maximize</span>
				</button>
				{activeMenu === 'menu3' && (
					<MenuOpen
						title={'Profil & Préférences'}
						children={'test3'}
						variant={'menuOpen-settings'}
					>
						{!isAnonymous && (
							<UserPresentation
								userPseudo={userPseudo}
								userLevel={userLevel}
							/>
						)}

						<TagsBox
							userId={userId}
							userPreferences={userPreferences}
							onPreferencesUpdate={onPreferencesUpdate} // Passer la fonction de rappel
							isAnonymous={isAnonymous}
						/>
						<SettingsUser />
					</MenuOpen>
				)}
			</div>
		</div>
	)
}

export default MenuNav
