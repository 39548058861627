import React, { useEffect } from 'react'

const Popup = ({
	text,
	onAction,
	isVisible,
	actionLabel,
	expireDate = null,
	onClose,
	className,
	children
}) => {
	useEffect(() => {
		// Vérifier la date d'expiration pour ne pas afficher le popup après cette date
		if (expireDate && new Date() > new Date(expireDate)) {
			onClose() // Fermer le popup si la date est passée
		}
	}, [expireDate, onClose])

	if (!isVisible) return null // Ne pas afficher si isVisible est faux

	return (
		<div className={`popup ${className}`}>
			<div className="popup__main">
				<p>{text}</p>
				{children}
				{actionLabel && (
					<button className="popup__action" onClick={onAction}>
						{actionLabel}
					</button>
				)}
			</div>
			<button
				className={`popup__close ${className}`}
				onClick={onClose}
				aria-label="Fermer"
			>
				&times;
			</button>
		</div>
	)
}

export default Popup
