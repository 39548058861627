import { Link } from 'react-router-dom'

import beletteMap from '../../assets/beletteMap.png'

const BoardPro = () => {
	const clearCache = () => {
		localStorage.clear() // Supprime toutes les données du localStorage
		sessionStorage.clear() // Supprime toutes les données du sessionStorage si nécessaire
	}

	return (
		<div className="BoardPro">
			<nav>
				<Link to="/" onClick={clearCache}>
					Retourner sur la page d'accueil
				</Link>
			</nav>

			<div className="BoardPro__title">
				<img src={beletteMap} alt="Logo du site, une belette marron" />
				<div>
					<h1>Section en cours de construction</h1>
					<h2>revenez dans quelques jours voir les nouveautés ! </h2>
				</div>
			</div>
		</div>
	)
}

export default BoardPro
