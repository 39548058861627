///////////////Librairies///////////////
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

///////////////Pages///////////////
import AuthChoice from './pages/AuthChoice'
import Nofound from './pages/Nofound'
import Login from './pages/Login'
import Signup from './pages/Signup'
import Home from './pages/Home'
import Admin from './pages/Admin'
import BoardPro from './pages/BoardPro/BoardPro'
import Cgu from './pages/Cgu'

///////////////Services///////////////
import ProtectedRoute from './services/protectedRoute'
import ProtectedRouteAdmin from './services/protectedRouteAdmin'
import RedirectIfAuthenticated from './services/redirectIfAuthenticated'

///////////////Logique///////////////
function App() {
	return (
		<div className="app">
			<Router>
				<Routes>
					<Route
						path="/auth-choice"
						element={
							<RedirectIfAuthenticated>
								<AuthChoice />
							</RedirectIfAuthenticated>
						}
					/>
					<Route
						path="/Login"
						element={
							<RedirectIfAuthenticated>
								<Login />
							</RedirectIfAuthenticated>
						}
					/>
					<Route
						path="/Signup"
						element={
							<RedirectIfAuthenticated>
								<Signup />
							</RedirectIfAuthenticated>
						}
					/>
					{/* Route protégée pour la page d'accueil */}
					<Route
						path="/"
						element={
							<ProtectedRoute>
								<Home />
							</ProtectedRoute>
						}
					/>

					<Route
						path="/WeaselSpace"
						element={
							<ProtectedRoute>
								<BoardPro />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin-WeaselMap-console"
						element={
							<ProtectedRouteAdmin>
								<Admin />
							</ProtectedRouteAdmin>
						}
					/>
					<Route path="/WeaselMap-CGU" element={<Cgu />} />
					<Route path="/*" element={<Nofound />} />
				</Routes>
			</Router>
		</div>
	)
}

export default App
