import React from 'react'
import ToggleFilters from '../Btn-ToggleFilters'
import CenterOnUserButton from '../Btn-CenterUser'
import ReportButton from '../Btn-Report/BtnReport'

const MapControls = ({
	userStatus,
	userEmail,
	handleAdmin,
	handlePro,
	isEmailVerified,
	openModal,
	filtersEnabled,
	setFiltersEnabled,
	searchWithinMapBounds,
	centerOnUserLocation
}) => {
	return (
		<div className="map-controls">
			{userStatus === 'admin' && (
				<button
					className="map-controls__button admin-button"
					onClick={handleAdmin}
					title="Admin Console"
				>
					<span className="buttonText">Administration</span>
					<span className="material-symbols-outlined">
						admin_panel_settings
					</span>
				</button>
			)}

			{(userStatus === 'pro' || userStatus === 'asso') && (
				<button
					className="map-controls__button admin-button"
					onClick={handlePro}
					title="Page professionelle"
				>
					<span className="buttonText">
						{userStatus === 'pro' ? 'Ma page Pro' : 'Ma page Asso'}
					</span>
					<span className="material-symbols-outlined">cottage</span>
				</button>
			)}

			{isEmailVerified && (
				<button
					className="map-controls__button add-place-button"
					onClick={openModal}
					title="Ajouter un lieu"
				>
					<span className="buttonText">Ajouter un lieu</span>
					<span className="material-symbols-outlined">
						add_location_alt
					</span>
				</button>
			)}

			<ToggleFilters
				filtersEnabled={filtersEnabled}
				setFiltersEnabled={setFiltersEnabled}
				className={'map-controls__toggle-filters'}
			/>

			<button
				className="map-controls__button search-area-button"
				onClick={searchWithinMapBounds}
				title="Rechercher dans la zone"
			>
				<span className="buttonText">Rechercher dans la zone</span>
				<span className="material-symbols-outlined">pageview</span>
			</button>

			<CenterOnUserButton
				centerOnUserLocation={centerOnUserLocation}
				className="map-controls__button center-user-button"
			/>
			{userStatus !== 'admin' ? (
				<ReportButton
					type={'contact'}
					className={'-bug'}
					userStatus={userStatus}
				/>
			) : (
				<ReportButton
					type={'contact'}
					className={'-bug'}
					userStatus={userStatus}
					isEmailVerified={isEmailVerified}
					userEmail={userEmail}
				/>
			)}
		</div>
	)
}

export default MapControls
