const ToggleFilters = ({
	type,
	className,
	filtersEnabled,
	setFiltersEnabled
}) => {
	// Fonction pour activer/désactiver les filtres
	const toggleFilters = () => {
		setFiltersEnabled((prevState) => !prevState)
	}

	return (
		<>
			{className === 'map-controls__toggle-filters' ? (
				<>
					<button
						onClick={toggleFilters}
						className={`map-controls__button
					 ${filtersEnabled ? 'activeFilters' : ''} ${className}`}
						type={type}
						title="Appliquer les filtres"
					>
						<span className="buttonText">
							{filtersEnabled
								? 'Désactiver les filtres'
								: 'Activer les filtres'}
						</span>

						{filtersEnabled ? (
							<span className="material-symbols-outlined">
								filter_alt
							</span>
						) : (
							<span className="material-symbols-outlined">
								filter_alt_off
							</span>
						)}
					</button>
				</>
			) : (
				<button
					onClick={toggleFilters}
					className={`toggleFilters ${
						filtersEnabled ? 'active' : ''
					} ${className}`}
					type={type}
				>
					<>
						<span className="toggleSwitch">
							<span className="toggleSwitch__circle"></span>
						</span>
						<p>
							{filtersEnabled
								? 'Filtres activés'
								: 'Filtres désactivés'}
						</p>
					</>
				</button>
			)}
		</>
	)
}

export default ToggleFilters
