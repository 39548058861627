import { useEffect } from 'react'
import PropTypes from 'prop-types'

function Modal({ isOpen, onClose, children, className }) {
	// Ferme la modale en cliquant en dehors d'elle
	useEffect(() => {
		function handleOutsideClick(event) {
			if (event.target.classList.contains('modal-overlay')) {
				onClose()
			}
		}

		if (isOpen) {
			document.addEventListener('click', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [isOpen, onClose])

	if (!isOpen) return null // Si la modale n'est pas ouverte, ne rien afficher

	return (
		<div className="modal-overlay">
			<div className={`modal ${className}`}>
				<button className="modal-close" onClick={onClose}>
					&times;
				</button>
				<div className="modal-content">{children}</div>
			</div>
		</div>
	)
}

Modal.propTypes = {
	isOpen: PropTypes.bool.isRequired, // booléen pour déterminer si la modale est ouverte
	onClose: PropTypes.func.isRequired, // fonction pour fermer la modale
	children: PropTypes.node.isRequired // contenu de la modale
}

export default Modal
