import React from 'react'

const CenterOnUserButton = ({ centerOnUserLocation, className }) => {
	return (
		<button
			className={`CenterOnUserButton ${className}`}
			onClick={centerOnUserLocation}
			title="Recentrer sur ma position"
		>
			<span className="buttonText">Recentrer sur ma position</span>
			<span className="material-symbols-outlined">adjust</span>
		</button>
	)
}

export default CenterOnUserButton
