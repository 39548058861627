import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
	collection,
	getDocs,
	getDoc,
	deleteDoc,
	updateDoc,
	query,
	where,
	doc
} from 'firebase/firestore'
import { firestore } from '../../services/firebaseConfig'

// Components
import Modal from '../../components/Objects/Modal'
import AddPlaceForm from '../../components/Objects/Form-AddPlace/AddPlaceForm'

const Admin = () => {
	const [proposals, setProposals] = useState([])
	const [mode, setMode] = useState('')
	const [selectedPlace, setSelectedPlace] = useState(null)
	const [reports, setReports] = useState([])
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isNewPlacesOpen, setIsNewPlacesOpen] = useState(true)
	const [isUpdatesOpen, setIsUpdatesOpen] = useState(false)
	const [isReportsOpen, setIsReportsOpen] = useState(false)
	const [isContactsOpen, setIsContactsOpen] = useState(false)
	const [isReportModalOpen, setIsReportModalOpen] = useState(false)
	const [selectedReport, setSelectedReport] = useState(null)
	const [reportedCommentData, setReportedCommentData] = useState(null)
	const [offendingUserEmail, setOffendingUserEmail] = useState(null)

	// Fonctions pour basculer l'ouverture des sections
	const toggleNewPlaces = () => setIsNewPlacesOpen((prev) => !prev)
	const toggleUpdates = () => setIsUpdatesOpen((prev) => !prev)
	const toggleReports = () => setIsReportsOpen((prev) => !prev)
	const toggleContacts = () => setIsContactsOpen((prev) => !prev)

	// Fonction pour récupérer les propositions et les signalements depuis Firestore
	const fetchData = async () => {
		// Récupérer les propositions
		const proposalsSnapshot = await getDocs(
			collection(firestore, 'proposals')
		)
		const proposalList = proposalsSnapshot.docs
			.map((doc) => ({
				id: doc.id,
				...doc.data()
			}))
			// Trier par date de soumission (du plus ancien au plus récent)
			.sort((a, b) => {
				const dateA = a.submissionDate?.seconds || 0
				const dateB = b.submissionDate?.seconds || 0
				return dateA - dateB
			})
		setProposals(proposalList)

		// Récupérer les signalements
		const reportsSnapshot = await getDocs(collection(firestore, 'reports'))
		const reportsList = reportsSnapshot.docs
			.map((doc) => ({
				id: doc.id,
				...doc.data()
			}))
			// Trier par date de soumission (du plus ancien au plus récent)
			.sort((a, b) => {
				const dateA = a.timestamp?.seconds || 0
				const dateB = b.timestamp?.seconds || 0
				return dateA - dateB
			})
		setReports(reportsList)
	}

	// Trier les rapports par type
	const reportSignals = reports.filter(
		(report) => report.type === 'place' || report.type === 'comment'
	)
	const reportContacts = reports.filter((report) => report.type === 'contact')

	// Chargement initial des données
	useEffect(() => {
		fetchData()
	}, [])

	// Séparer les propositions en "Nouveaux lieux" et "Demandes de MAJ"
	const newPlaces = proposals.filter((proposal) => !proposal.update)
	const updates = proposals.filter((proposal) => proposal.update)

	// Fonctions pour vérifier l'ancienneté d'une demande et formater la date
	const isOlderThan7Days = (date) => {
		if (!date) return false
		let submissionDate
		if (date.toDate) {
			submissionDate = date.toDate()
		} else if (date.seconds) {
			submissionDate = new Date(date.seconds * 1000)
		} else {
			submissionDate = new Date(date)
		}
		const currentDate = new Date()
		const timeDifference = currentDate - submissionDate
		const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
		return daysDifference > 7
	}

	const formatDate = (date) => {
		if (!date) return ''
		let submissionDate
		if (date.toDate) {
			submissionDate = date.toDate()
		} else if (date.seconds) {
			submissionDate = new Date(date.seconds * 1000)
		} else {
			submissionDate = new Date(date)
		}
		return submissionDate.toLocaleDateString()
	}

	// Fonctions de suppression
	const handleDeleteProposal = async (id) => {
		const confirmDelete = window.confirm(
			'Êtes-vous sûr de vouloir supprimer cette proposition ?'
		)
		if (confirmDelete) {
			try {
				await deleteDoc(doc(firestore, 'proposals', id))
				setProposals((prev) =>
					prev.filter((proposal) => proposal.id !== id)
				)
			} catch (error) {
				console.error(
					'Erreur lors de la suppression de la proposition:',
					error
				)
			}
		}
	}

	const handleDeleteReport = async (id) => {
		const confirmDelete = window.confirm(
			'Êtes-vous sûr de vouloir supprimer ce signalement ?'
		)
		if (confirmDelete) {
			try {
				await deleteDoc(doc(firestore, 'reports', id))
				setReports((prev) => prev.filter((report) => report.id !== id))
			} catch (error) {
				console.error(
					'Erreur lors de la suppression du signalement:',
					error
				)
			}
		}
	}

	const updateAverageRating = async (placeId) => {
		const ratingsSnapshot = await getDocs(
			query(
				collection(firestore, 'ratings'),
				where('placeId', '==', placeId)
			)
		)

		let totalRatings = 0
		let ratingsSum = 0

		ratingsSnapshot.forEach((doc) => {
			ratingsSum += doc.data().rating
			totalRatings++
		})

		const averageRating = totalRatings > 0 ? ratingsSum / totalRatings : 0

		await updateDoc(doc(firestore, `heritage/${placeId}`), {
			'properties.rating.average': averageRating,
			'properties.rating.ratingsCount': totalRatings
		})
	}

	// Ouvrir la modale et définir le lieu sélectionné
	const handleSeeMore = (placeId) => {
		const place = proposals.find((proposal) => proposal.id === placeId)
		setSelectedPlace(place)
		setMode(place.update ? 'update' : 'creation') // Set the mode here
		setIsModalOpen(true)
	}

	const handleSeeReport = async (report) => {
		if (report.type === 'comment') {
			const commentId = report.commentId

			try {
				// Récupérer le commentaire signalé
				const commentDoc = await getDoc(
					doc(firestore, 'ratings', commentId)
				)
				if (commentDoc.exists()) {
					const commentData = commentDoc.data()
					setReportedCommentData(commentData)

					// Récupérer l'email de l'utilisateur qui a fait le commentaire
					const userId = commentData.userId
					const userDoc = await getDoc(
						doc(firestore, 'users', userId)
					)
					if (userDoc.exists()) {
						const userEmail = userDoc.data().email
						setOffendingUserEmail(userEmail)
					} else {
						setOffendingUserEmail('Inconnu')
					}

					// Stocker le signalement sélectionné
					setSelectedReport(report)

					// Ouvrir la modale
					setIsReportModalOpen(true)
				} else {
					console.error('Commentaire non trouvé')
					alert('Commentaire non trouvé')
				}
			} catch (error) {
				console.error(
					'Erreur lors de la récupération du commentaire signalé:',
					error
				)
			}
		} else if (report.type === 'contact' || report.type === 'place') {
			// Pour les signalements de type 'contact', pas besoin de récupérer d'autres données
			setSelectedReport(report)
			setIsReportModalOpen(true)
		}
	}

	const handleDeleteComment = async () => {
		if (reportedCommentData && selectedReport.commentId) {
			const confirmDelete = window.confirm(
				'Êtes-vous sûr de vouloir supprimer ce commentaire ?'
			)
			if (confirmDelete) {
				try {
					// Supprimer le commentaire
					await deleteDoc(
						doc(firestore, 'ratings', selectedReport.commentId)
					)

					// Mettre à jour la note moyenne du lieu
					await updateAverageRating(selectedReport.placeId)

					// Supprimer le signalement
					await deleteDoc(
						doc(firestore, 'reports', selectedReport.id)
					)
					setReports((prev) =>
						prev.filter((report) => report.id !== selectedReport.id)
					)

					// Fermer la modale
					setIsReportModalOpen(false)

					alert('Commentaire supprimé avec succès')
				} catch (error) {
					console.error(
						'Erreur lors de la suppression du commentaire:',
						error
					)
				}
			}
		}
	}

	//TO DO : Pas de vérification des utilisateurs bannis au login // Pas de régle firestore pour le gérer
	const handleBanUser = async () => {
		if (reportedCommentData && reportedCommentData.userId) {
			const confirmBan = window.confirm(
				'Êtes-vous sûr de vouloir bannir cet utilisateur ?'
			)
			if (confirmBan) {
				try {
					// Mettre à jour le statut de l'utilisateur
					await updateDoc(
						doc(firestore, 'users', reportedCommentData.userId),
						{
							banned: true
						}
					)

					alert('Utilisateur banni avec succès')
				} catch (error) {
					console.error(
						"Erreur lors du bannissement de l'utilisateur:",
						error
					)
				}
			}
		}
	}

	// Gérer la fermeture la modale
	const closeModal = () => {
		setSelectedPlace(null)
		setIsModalOpen(false)
	}

	const clearCache = () => {
		const excludeKey = 'changelog_2024-10-31'

		// Stocker la valeur à conserver
		const excludedValue = localStorage.getItem(excludeKey)

		// Supprimer tous les éléments du localStorage
		localStorage.clear()

		// Restaurer l'élément exclu
		if (excludedValue !== null) {
			localStorage.setItem(excludeKey, excludedValue)
		}

		// Supprimer toutes les données du sessionStorage si nécessaire
		sessionStorage.clear()
	}

	return (
		<div className="admin">
			<nav>
				<Link to="/" onClick={clearCache}>
					Retourner sur la page d'accueil
				</Link>
				<button
					onClick={fetchData}
					className="admin__refresh-button"
					title="Refresh"
				>
					<span className="material-symbols-outlined">sync</span>
				</button>
			</nav>
			<h1>Console d'administration</h1>
			<div className="admin__console">
				<div className="admin__console__column">
					<div className="collapsible admin__console__news">
						<h2
							onClick={toggleNewPlaces}
							className="collapsible-title"
						>
							Nouveaux lieux ({newPlaces.length})
							<span className="material-symbols-outlined">
								{isNewPlacesOpen
									? 'expand_more'
									: 'expand_less'}
							</span>
						</h2>
						<div className="admin__console__news--list">
							{isNewPlacesOpen && (
								<div className="admin__console__news-list">
									{newPlaces.map((proposal) => (
										<div
											key={proposal.id}
											className={`proposal ${
												isOlderThan7Days(
													proposal.submissionDate
												)
													? 'proposal-alerte'
													: ''
											}`}
										>
											<div className="proposal-id">
												<div>
													<span>
														Email :
														{proposal.userEmail}
													</span>
													<span>
														Date :
														{formatDate(
															proposal.submissionDate
														)}
													</span>
												</div>
												<button
													className="admin__console-delete"
													onClick={(e) => {
														e.stopPropagation()
														handleDeleteProposal(
															proposal.id
														)
													}}
												>
													<span className="material-symbols-outlined">
														delete
													</span>
												</button>
											</div>
											<div
												className="proposal-item"
												onClick={() =>
													handleSeeMore(proposal.id)
												}
											>
												<div className="proposal-item-place">
													<h3>
														{
															proposal.properties
																.title
														}
													</h3>
													<p>
														Adresse :
														{
															proposal.properties
																.address
														}
														,
														<br />
														{
															proposal.properties
																.city
														}
														{
															proposal.properties
																.postalCode
														}
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
					<div className="collapsible admin__console__updates">
						<h2
							onClick={toggleUpdates}
							className="collapsible-title"
						>
							Demandes de MAJ ({updates.length})
							<span className="material-symbols-outlined">
								{isUpdatesOpen ? 'expand_more' : 'expand_less'}
							</span>
						</h2>
						<div className="admin__console__updates--list">
							{isUpdatesOpen && (
								<div className="admin__console__updates-list">
									{updates.map((proposal) => (
										<div
											key={proposal.id}
											className={`proposal ${
												isOlderThan7Days(
													proposal.submissionDate
												)
													? 'proposal-alerte'
													: ''
											}`}
										>
											<div className="proposal-id">
												<div>
													<span>
														Email :
														{proposal.userEmail}
													</span>
													<span>
														Date :
														{formatDate(
															proposal.submissionDate
														)}
													</span>
												</div>
												<button
													className="admin__console-delete"
													onClick={(e) => {
														e.stopPropagation()
														handleDeleteProposal(
															proposal.id
														)
													}}
												>
													<span className="material-symbols-outlined">
														delete
													</span>
												</button>
											</div>
											<div
												className="proposal-item"
												onClick={() =>
													handleSeeMore(proposal.id)
												}
											>
												<div className="proposal-item-place">
													<p>
														Adresse :
														{
															proposal.properties
																.address
														}
														,
														<br />
														{
															proposal.properties
																.city
														}
														{
															proposal.properties
																.postalCode
														}
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</div>

				<div className="admin__console__column">
					<div className="collapsible admin__console__reports">
						<h2
							onClick={toggleReports}
							className="collapsible-title"
						>
							Signalements ({reportSignals.length})
							<span className="material-symbols-outlined">
								{isReportsOpen ? 'expand_more' : 'expand_less'}
							</span>
						</h2>
						<div className="admin__console__reports--list">
							{isReportsOpen && (
								<div className="admin__console__reports-list">
									{reportSignals.map((report) => (
										<div
											key={report.id}
											className={`proposal ${
												isOlderThan7Days(
													report.timestamp
												)
													? 'proposal-alerte'
													: ''
											}`}
										>
											<div className="proposal-id">
												<div>
													<span>
														Email:
														{report.userEmail}
													</span>
													<span>
														Date :
														{formatDate(
															report.timestamp
														)}
													</span>
												</div>
												<button
													className="admin__console-delete"
													onClick={(e) => {
														e.stopPropagation()
														handleDeleteReport(
															report.id
														)
													}}
												>
													<span className="material-symbols-outlined">
														delete
													</span>
												</button>
											</div>
											<div
												className="proposal-item"
												onClick={() =>
													handleSeeReport(report)
												}
											>
												<div className="proposal-item-place">
													<p>Type: {report.type}</p>
													<p>
														Message:
														{report.message.slice(
															0,
															50
														)}
														{report.message.length >
														50
															? '...'
															: ''}
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>

					<div className="collapsible admin__console__contacts">
						<h2
							onClick={toggleContacts}
							className="collapsible-title"
						>
							Contacts ({reportContacts.length})
							<span className="material-symbols-outlined">
								{isContactsOpen ? 'expand_more' : 'expand_less'}
							</span>
						</h2>
						<div className="admin__console__contacts--list">
							{isContactsOpen && (
								<div className="admin__console__contacts-list">
									{reportContacts.map((report) => (
										<div
											key={report.id}
											className={`proposal ${
												isOlderThan7Days(
													report.timestamp
												)
													? 'proposal-alerte'
													: ''
											}`}
										>
											<div className="proposal-id">
												<div>
													<span>
														Email:
														{report.userEmail}
													</span>
													<span>
														Date :
														{formatDate(
															report.timestamp
														)}
													</span>
												</div>
												<button
													className="admin__console-delete"
													onClick={(e) => {
														e.stopPropagation()
														handleDeleteReport(
															report.id
														)
													}}
												>
													<span className="material-symbols-outlined">
														delete
													</span>
												</button>
											</div>
											<div
												className="proposal-item"
												onClick={() =>
													handleSeeReport(report)
												}
											>
												<div className="proposal-item-place">
													<p>Type: {report.type}</p>
													<p>
														Message:
														{report.message.slice(
															0,
															50
														)}
														{report.message.length >
														50
															? '...'
															: ''}
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					</div>
				</div>

				{/* Afficher la modale si isModalOpen est vrai */}
				{isModalOpen && (
					<Modal
						isOpen={isModalOpen}
						onClose={() => {
							closeModal()
							handleDeleteProposal(selectedPlace.id)
						}}
						className={'addPlaceForm__modal'}
					>
						<AddPlaceForm
							existingData={[selectedPlace]}
							onClose={() => {
								closeModal()
								handleDeleteProposal(selectedPlace.id)
							}}
							userStatus="admin"
							placeId={selectedPlace.placeId}
							mode={mode}
						/>
					</Modal>
				)}

				{isReportModalOpen && (
					<Modal
						isOpen={isReportModalOpen}
						onClose={() => {
							setIsReportModalOpen(false)
							handleDeleteReport(selectedReport.id)
						}}
						className="addPlaceForm__modal"
					>
						<div className="report-modal-content">
							{selectedReport.type === 'comment' &&
								reportedCommentData && (
									<>
										<h2>Signalement du commentaire</h2>
										<div className="report">
											<div className="report-message">
												<p>
													<strong>
														Email du signalement :
													</strong>
													<br />
													{selectedReport.userEmail}
												</p>
												<p>
													<strong>
														Message du signalement :
													</strong>
													<br />
													{selectedReport.message}
												</p>
											</div>
											<div className="report-comment">
												<p>
													<strong>
														Commentaire signalé :
													</strong>
													<br />
													{
														reportedCommentData.comment
													}
												</p>
												<p>
													<strong>
														Email de l'utilisateur :
													</strong>
													<br />
													{offendingUserEmail}
												</p>
												<p>
													<strong>
														ID du lieu :
													</strong>
													<br />
													{selectedReport.placeId}
												</p>
												<p>
													<strong>
														Date du commentaire :
													</strong>
													<br />
													{formatDate(
														reportedCommentData.timestamp
													)}
												</p>
											</div>
										</div>
										<div className="report-modal-actions">
											<button
												onClick={handleDeleteComment}
											>
												Supprimer le commentaire
											</button>
											<button onClick={handleBanUser}>
												Bannir l'utilisateur
											</button>
										</div>
									</>
								)}

							{selectedReport.type === 'contact' && (
								<div className="contact">
									<h2>Message de contact</h2>
									<div className="contact-message">
										<p>
											<strong>
												Email de l'expéditeur :
											</strong>
											<br />
											{selectedReport.userEmail}
										</p>
										<p>
											<strong>Date :</strong> <br />
											{formatDate(
												selectedReport.timestamp
											)}
										</p>
									</div>
									<div className="contact-comment">
										<p>
											<strong>Message :</strong> <br />
											{selectedReport.message}
										</p>
									</div>
								</div>
							)}

							{selectedReport.type === 'place' && (
								<div className="contact">
									<h2>Signalemment sur un lieu</h2>
									<div className="contact-message">
										<p>
											<strong>
												Email de l'expéditeur :
											</strong>
											<br />
											{selectedReport.userEmail}
										</p>
										<p>
											<strong>Nom du lieu :</strong>{' '}
											<br />
											{selectedReport.elementName}
										</p>
										<p>
											<strong>ID : </strong>
											{selectedReport.reportId}
										</p>
									</div>
									<div className="contact-comment">
										<p>
											<strong>Message :</strong> <br />
											{selectedReport.message}
										</p>
									</div>
								</div>
							)}
						</div>
					</Modal>
				)}
			</div>
		</div>
	)
}

export default Admin
