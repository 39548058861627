import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { firestore, storage } from '../../../services/firebaseConfig'
import { addDoc, updateDoc, getDoc, doc, collection } from 'firebase/firestore'
import {
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject
} from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid' // Permet d'uniformiser le nom des images stockées dans storage
import imageCompression from 'browser-image-compression'

////Components////
import MiniMap from '../Map-Mini/MiniMap'

import tagsList from '../../../data/tags.json'

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_TOKEN

const AddPlaceForm = ({
	onClose,
	initialLatitude,
	initialLongitude,
	userStatus,
	userEmail,
	userId,
	existingData = [],
	placeId,
	mode
}) => {
	const [title, setTitle] = useState('')
	const [address, setAddress] = useState('')
	const [postalCode, setPostalCode] = useState('')
	const [city, setCity] = useState('')
	const [latitude, setLatitude] = useState(initialLatitude || '')
	const [longitude, setLongitude] = useState(initialLongitude || '')
	const [isAddressChanged, setIsAddressChanged] = useState(false)
	const [createdByFiled, setCreatedByField] = useState(
		existingData?.[0]?.properties?.createdBy
	)
	const [suggestions, setSuggestions] = useState([])
	const [descriptionFR, setDescriptionFR] = useState('')
	const [descriptionEN, setDescriptionEN] = useState('')
	const [selectedTags, setSelectedTags] = useState([])
	const [pictures, setPictures] = useState([])
	const [picturePreviews, setPicturePreviews] = useState([])
	const [error, setError] = useState('')
	const [successMessage, setSuccessMessage] = useState('')
	const [existingPictures, setExistingPictures] = useState([])
	const [imagesToDelete, setImagesToDelete] = useState([])

	// Mettre à jour les états si `existingData` est présent
	useEffect(() => {
		if (
			existingData &&
			Array.isArray(existingData) &&
			existingData.length === 1
		) {
			const data = existingData[0]
			const properties = data.properties || {}
			const coordinates = data.geometry?.coordinates || [
				initialLongitude,
				initialLatitude
			]

			setTitle(properties.title || '')
			setAddress(properties.address || '')
			setPostalCode(properties.postalCode || '')
			setCity(properties.city || '')
			setDescriptionFR(properties.descriptionFR || '')
			setDescriptionEN(properties.descriptionEN || '')
			setSelectedTags(properties.tags || [])
			setLatitude(coordinates[1] || initialLatitude || '')
			setLongitude(coordinates[0] || initialLongitude || '')

			// Gérer les images existantes
			if (properties.pictures && properties.pictures.length > 0) {
				setExistingPictures(properties.pictures)
			}
		}
	}, [existingData, initialLatitude, initialLongitude])

	const handleRemoveExistingPicture = (index) => {
		const urlToRemove = existingPictures[index]

		// Ajouter l'image à imagesToDelete pour suppression après validation
		setImagesToDelete((prev) => [...prev, urlToRemove])

		// Retirer l'URL de l'image de l'état local sans la supprimer du stockage pour l'instant
		setExistingPictures((prev) => prev.filter((_, i) => i !== index))
	}

	function clearCache() {
		localStorage.removeItem(placeId)
	}

	// Fonction pour gérer la sélection/désélection d'un tag
	const handleTagClick = (tag) => {
		setSelectedTags((prev) =>
			prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
		)
	}

	// Gérer l'ajout de photos et créer des miniatures
	const handlePictureChange = (e) => {
		const newPictures = Array.from(e.target.files)

		// Si l'utilisateur n'est pas admin, limiter à 3 photos maximum
		if (
			userStatus !== 'admin' &&
			pictures.length + newPictures.length > 3
		) {
			setError("Vous ne pouvez ajouter que jusqu'à 3 photos.")
			return
		}

		setPictures((prev) => [...prev, ...newPictures])
		setPicturePreviews((prev) => [
			...prev,
			...newPictures.map((file) => URL.createObjectURL(file))
		])
	}

	// Gérer la suppression d'une photo
	const handleRemovePicture = (index) => {
		setPictures((prev) => prev.filter((_, i) => i !== index))
		setPicturePreviews((prev) => prev.filter((_, i) => i !== index))
	}

	// Gérer le drag-and-drop pour ajouter des photos
	const handleDrop = (e) => {
		e.preventDefault()
		const droppedFiles = Array.from(e.dataTransfer.files)

		// Si l'utilisateur n'est pas admin, limiter à 3 photos maximum
		if (
			userStatus !== 'admin' &&
			pictures.length + droppedFiles.length > 3
		) {
			setError("Vous ne pouvez ajouter que jusqu'à 3 photos.")
			return
		}

		setPictures((prev) => [...prev, ...droppedFiles])
		setPicturePreviews((prev) => [
			...prev,
			...droppedFiles.map((file) => URL.createObjectURL(file))
		])
	}

	// Fonction pour convertir et optimiser les images en WebP avant de les uploader
	const convertAndUploadImages = async () => {
		const compressedImageUrls = await Promise.all(
			pictures.map(async (picture) => {
				// Options de compression
				const options = {
					maxSizeMB: 0.75, // Taille maximale en MB
					maxWidthOrHeight: 1024, // Dimension maximale
					useWebWorker: true,
					fileType: 'image/webp', // Convertir en WebP
					quality: 0.8
				}

				try {
					// Compression et conversion de l'image
					const compressedFile = await imageCompression(
						picture,
						options
					)

					// Générer un nom de fichier unique en .webp
					const uniqueName = `${title
						.replace(/\.[^/.]+$/, '')
						.toLowerCase()
						.replace(/[^a-z0-9]/g, '_')}_${uuidv4()}.webp`

					// Définir une référence Firebase Storage avec le nom unique
					const pictureRef = ref(
						storage,
						`heritage-pictures/${uniqueName}`
					)

					// Télécharger le fichier compressé dans Firebase Storage
					await uploadBytes(pictureRef, compressedFile)

					// Obtenir l'URL de téléchargement
					return await getDownloadURL(pictureRef)
				} catch (error) {
					console.error(
						"Erreur lors de la compression de l'image :",
						error
					)
					throw error
				}
			})
		)
		return compressedImageUrls
	}

	const handleAddressChange = async (newAddress) => {
		setAddress(newAddress)
		setIsAddressChanged(true)

		if (newAddress.length > 3) {
			try {
				const response = await fetch(
					`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
						newAddress
					)}.json?access_token=${MAPBOX_API_KEY}&autocomplete=true&types=address`
				)
				const data = await response.json()

				if (data.features) {
					setSuggestions(
						data.features.map((feature) => ({
							label: feature.place_name,
							latitude: feature.center[1],
							longitude: feature.center[0],
							postalCode:
								feature.context?.find((c) =>
									c.id.startsWith('postcode')
								)?.text || '',
							city:
								feature.context?.find((c) =>
									c.id.startsWith('place')
								)?.text || ''
						}))
					)
				}
			} catch (error) {
				console.error(
					'Erreur lors de la récupération des suggestions:',
					error
				)
				setSuggestions([])
			}
		} else {
			setSuggestions([])
		}
	}

	const handleSuggestionSelect = (suggestion) => {
		// Extraire l'adresse "pure" en retirant le code postal, la ville et le pays
		const filteredAddress = suggestion.label
			.split(',')
			.slice(0, 1) // Garder uniquement la première partie (l'adresse)
			.join(',')
			.trim()

		setAddress(filteredAddress) // Mettre à jour le champ adresse
		setPostalCode(suggestion.postalCode) // Mettre à jour le code postal
		setCity(suggestion.city) // Mettre à jour la ville
		setLatitude(suggestion.latitude) // Mettre à jour la latitude
		setLongitude(suggestion.longitude) // Mettre à jour la longitude
		setSuggestions([]) // Masquer les suggestions après sélection
	}

	// Fonction pour géocoder une adresse complète et obtenir les coordonnées avec Mapbox
	const geocodeAddress = async (
		address,
		postalCode,
		city,
		proximity = null
	) => {
		const fullAddress = `${address}, ${postalCode} ${city}`
		const proximityParam = proximity
			? `&proximity=${proximity.longitude},${proximity.latitude}`
			: ''

		try {
			// Essai avec l'adresse complète en priorisant la proximité
			const response = await fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
					fullAddress
				)}.json?access_token=${MAPBOX_API_KEY}&types=address,place&country=fr${proximityParam}`
			)
			const data = await response.json()

			if (data.features.length > 0) {
				const [lng, lat] = data.features[0].center
				return { latitude: lat, longitude: lng }
			}

			// Fallback : géocode code postal et ville avec proximité
			const fallbackResponse = await fetch(
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
					postalCode + ' ' + city
				)}.json?access_token=${MAPBOX_API_KEY}&country=fr${proximityParam}`
			)
			const fallbackData = await fallbackResponse.json()

			if (fallbackData.features.length > 0) {
				const [lng, lat] = fallbackData.features[0].center
				return { latitude: lat, longitude: lng }
			}

			// Dernier recours avec Nominatim sans priorité
			const nominatimResponse = await fetch(
				`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
					fullAddress
				)}`
			)
			const nominatimData = await nominatimResponse.json()

			if (nominatimData.length > 0) {
				const { lat, lon } = nominatimData[0]
				return { latitude: parseFloat(lat), longitude: parseFloat(lon) }
			}

			throw new Error('Aucun résultat trouvé')
		} catch (error) {
			console.error('Erreur de géocodage:', error)
			throw error
		}
	}

	// Fonction pour gérer la soumission du formulaire
	const handleSubmit = async (e) => {
		e.preventDefault()

		if (
			!title ||
			!address ||
			!postalCode ||
			!city ||
			!descriptionFR ||
			selectedTags.length === 0
		) {
			setError('Veuillez remplir tous les champs requis.')
			setSuccessMessage('')
			return
		}

		try {
			let coords = { latitude, longitude }

			// Géocoder si nécessaire
			if (!latitude || !longitude) {
				coords = await geocodeAddress(address, postalCode, city)
				setLatitude(coords.latitude)
				setLongitude(coords.longitude)
			}

			// Conversion et téléchargement des images
			const pictureUrls = await convertAndUploadImages()

			// Combiner les images existantes (après retrait des images à supprimer) avec les nouvelles
			const filteredExistingPictures = existingPictures.filter(
				(url) => !imagesToDelete.includes(url)
			)
			const allPictures = [...filteredExistingPictures, ...pictureUrls]

			// Conserver les données existantes de rating si disponibles
			const existingRating = existingData?.[0]?.properties?.rating || {
				average: 0,
				ratingsCount: 0
			}

			// Conserver les données existantes de createdBy si disponibles
			let createdByData = createdByFiled

			// Si `createdBy` est vide, récupérer le pseudo de l'utilisateur
			if (!createdByData) {
				const userDocRef = doc(firestore, 'users', userId)
				const userDoc = await getDoc(userDocRef)
				if (userDoc.exists()) {
					createdByData =
						userDoc.data().pseudo || 'Utilisateur inconnu'
				} else {
					throw new Error(
						'Utilisateur introuvable pour associer le pseudo.'
					)
				}
			}

			const placeData = {
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: [coords.longitude, coords.latitude]
				},
				properties: {
					title,
					address,
					postalCode,
					city,
					descriptionFR,
					descriptionEN,
					tags: selectedTags,
					pictures: allPictures,
					createdAt: new Date(),
					createdBy: createdByData, // Utilise les données existantes si disponibles
					rating: existingRating // Utilise les données existantes si disponibles
				},
				// Inclure l'ID du lieu existant si c'est une mise à jour
				...(mode === 'update' && userStatus !== 'admin' && { placeId }),
				// Inclure l'email de l'utilisateur si non-admin
				...(userStatus !== 'admin' && { userEmail }),
				// Inclure l'ID de l'utilisateur si non-admin
				...(userStatus !== 'admin' && { userId }),
				// Inclure la date de soumission si non-admin
				...(userStatus !== 'admin' && { submissionDate: new Date() }),
				// Indiquer qu'il s'agit d'une mise à jour si c'est le cas
				...(mode === 'update' &&
					userStatus !== 'admin' && { update: true })
			}

			// Supprimez les clés indéfinies de placeData
			Object.keys(placeData).forEach((key) => {
				if (placeData[key] === undefined) {
					delete placeData[key]
				}
			})

			// Logique en fonction du mode et du statut de l'utilisateur
			if (mode === 'creation') {
				if (userStatus === 'admin') {
					// Création d'un nouveau lieu dans la collection 'heritage'
					await addDoc(collection(firestore, 'heritage'), placeData)
					setSuccessMessage('Le lieu a été ajouté avec succès !')

					// Incrémenter le niveau de l'utilisateur
					const userDocRef = doc(
						firestore,
						'users',
						existingData?.[0]?.userId
					)
					const userDoc = await getDoc(userDocRef)
					if (userDoc.exists()) {
						const currentLevel = userDoc.data().level || 0
						await updateDoc(userDocRef, {
							level: currentLevel + 1
						})
					}
				} else {
					// Création d'une proposition dans 'proposals'
					await addDoc(collection(firestore, 'proposals'), placeData)
					setSuccessMessage(
						'Merci pour votre proposition ! Elle sera examinée par notre équipe.'
					)
				}
			} else if (mode === 'update') {
				if (userStatus === 'admin') {
					// Mise à jour du lieu existant dans 'heritage'
					if (!placeId) {
						throw new Error(
							'Le placeId est requis pour mettre à jour le lieu.'
						)
					}
					const placeRef = doc(firestore, 'heritage', placeId)
					await updateDoc(placeRef, placeData)
					clearCache(placeId)
					setSuccessMessage('Le lieu a été mis à jour avec succès !')
				} else {
					// Création d'une proposition de mise à jour dans 'proposals'
					await addDoc(collection(firestore, 'proposals'), placeData)
					setSuccessMessage(
						'Merci pour votre proposition de mise à jour ! Elle sera examinée par notre équipe.'
					)
				}
			}

			// Supprimer les images dans imagesToDelete du stockage Firebase (si admin)
			if (userStatus === 'admin') {
				await Promise.all(
					imagesToDelete.map(async (url) => {
						const imageRef = ref(storage, url)
						await deleteObject(imageRef)
					})
				)
			}

			// Réinitialiser les états
			setError('')
			setTitle('')
			setAddress('')
			setPostalCode('')
			setCity('')
			setDescriptionFR('')
			setDescriptionEN('')
			setPictures([])
			setPicturePreviews([])
			setSelectedTags([])
			setLatitude('')
			setLongitude('')
			setImagesToDelete([])

			// Fermer la modale après un délai
			setTimeout(() => {
				onClose(placeId)
			}, 2000)
		} catch (err) {
			console.error("Erreur lors de l'ajout du lieu :", err)
			setError(
				"Une erreur est survenue, n'hésitez pas à contacter l'équipe."
			)
			setSuccessMessage('')
		}
	}

	// Mettre à jour les coordonnées automatiquement si l'adresse, le code postal ou la ville sont changés
	useEffect(() => {
		if (
			isAddressChanged &&
			address.length > 3 &&
			postalCode.length > 3 &&
			city.length > 2
		) {
			geocodeAddress(address, postalCode, city)
				.then((coords) => {
					setLatitude(coords.latitude)
					setLongitude(coords.longitude)
				})
				.catch(() => {
					setLatitude('')
					setLongitude('')
				})
		}
	}, [address, postalCode, city, isAddressChanged])

	return (
		<form className="addPlaceForm" onSubmit={handleSubmit}>
			<h2>
				{mode === 'update'
					? 'Mettre à jour le lieu'
					: 'Ajouter un nouveau lieu'}
			</h2>

			{userStatus === 'admin' && (
				<>
					<label htmlFor="createdBy">Lieu proposé par :</label>
					<input
						type="text"
						id="createdBy"
						value={createdByFiled}
						onChange={(e) => {
							setCreatedByField(e.target.value)
						}}
						required
					/>
				</>
			)}

			<label htmlFor="title">Nom du lieu</label>
			<input
				type="text"
				id="title"
				value={title}
				onChange={(e) => {
					if (e.target.value.length <= 50) {
						setTitle(e.target.value)
					}
				}}
				required
			/>

			<label htmlFor="address">Adresse</label>
			<input
				type="text"
				id="address"
				value={address}
				onChange={(e) => handleAddressChange(e.target.value)}
				required
				autoComplete="off"
			/>

			{/* Liste déroulante pour les suggestions */}
			{userStatus === 'admin' && suggestions.length > 0 && (
				<ul className="address-suggestions">
					{suggestions.map((suggestion, index) => (
						<li
							key={index}
							onClick={() => handleSuggestionSelect(suggestion)}
						>
							{suggestion.label}
						</li>
					))}
				</ul>
			)}

			<div className="addPlaceForm__cityAndPostalCode">
				<div className="addPlaceForm__postalCode">
					<label htmlFor="postalCode">Code Postal</label>
					<input
						type="text"
						id="postalCode"
						value={postalCode}
						onChange={(e) => setPostalCode(e.target.value)}
						maxLength={5}
						required
						autoComplete="off"
					/>
				</div>
				<div className="addPlaceForm__city">
					<label htmlFor="city">Ville</label>
					<input
						type="text"
						id="city"
						value={city}
						onChange={(e) => setCity(e.target.value)}
						required
						autoComplete="off"
					/>
				</div>
			</div>

			{/* Cacher les champs de latitude et longitude si l'utilisateur n'est pas admin */}
			{userStatus === 'admin' && (
				<div className="addPlaceForm__ltdlng">
					<div className="addPlaceForm__ltd">
						<label htmlFor="latitude">Latitude :</label>
						<input
							type="number"
							id="latitude"
							value={latitude}
							onChange={(e) => setLatitude(e.target.value)}
							step="any"
						/>
					</div>
					<div className="addPlaceForm__lng">
						<label htmlFor="longitude">Longitude :</label>
						<input
							type="number"
							id="longitude"
							value={longitude}
							onChange={(e) => setLongitude(e.target.value)}
							step="any"
						/>
					</div>
				</div>
			)}

			{/* Affiche la miniMap uniquement si une adresse permettant de donnée une latitude a été indiqué */}
			{latitude && (
				<>
					<div className="addPlaceForm__mapPreview">
						<h4>Prévisualisation de la localisation</h4>
						<MiniMap
							latitude={latitude}
							longitude={longitude}
							onPositionChange={(newLatitude, newLongitude) => {
								setLatitude(newLatitude)
								setLongitude(newLongitude)
							}}
						/>
					</div>
					<p>
						Si nécessaire, ajustez la possition en cliquant sur la
						carte.
					</p>
				</>
			)}
			<label htmlFor="description" id="descriptionLabel">
				Description
			</label>
			<textarea
				id="description"
				value={descriptionFR}
				onChange={(e) => {
					if (e.target.value.length <= 750) {
						setDescriptionFR(e.target.value)
					}
				}}
				required
			/>
			<span
				className={
					descriptionFR.length >= 750
						? 'messageLength messageError'
						: 'messageLength'
				}
			>
				{' '}
				{descriptionFR.length}/750 caractères
			</span>

			{userStatus === 'admin' && (
				<>
					<label htmlFor="traduction" id="traductionLabel">
						Traduction
					</label>
					<textarea
						id="traduction"
						value={descriptionEN}
						onChange={(e) => {
							if (e.target.value.length <= 750) {
								setDescriptionEN(e.target.value)
							}
						}}
						required
					/>
					<span
						className={
							descriptionEN.length >= 750
								? 'messageLength messageError'
								: 'messageLength'
						}
					>
						{' '}
						{descriptionEN.length}/750 caractères
					</span>
				</>
			)}

			<div className="tagsBox addPlaceForm__tagsBox">
				<h4>Choisir les éléments caractéristiques du lieu :</h4>
				<div className="tagsBox__list">
					{tagsList.tags.map((tag) => (
						<button
							key={tag}
							type="button"
							className={
								selectedTags.includes(tag) ? 'selected' : ''
							}
							onClick={() => handleTagClick(tag)}
						>
							{tag}
						</button>
					))}
				</div>
			</div>

			{/* Zone de prévisualisation et drag-and-drop pour les photos */}
			<div className="addPlaceForm__picturesAndPreviews">
				<div
					className="addPlaceForm__pictures"
					onDrop={handleDrop}
					onDragOver={(e) => e.preventDefault()}
				>
					<input
						type="file"
						id="pictures"
						multiple
						accept="image/*"
						onChange={handlePictureChange}
					/>
					<p>Cliquez ici ou faites glisser des images les ajouter.</p>
					<span className="material-symbols-outlined">
						add_circle
					</span>
				</div>

				{/* Afficher les nouvelles images ajoutées */}
				{picturePreviews.map((preview, index) => (
					<div key={index} className="picturePreview">
						<img src={preview} alt="Prévisualisation" />
						<button
							type="button"
							className="picturePreview__remove"
							onClick={() => handleRemovePicture(index)}
						>
							<span className="material-symbols-outlined">
								cancel
							</span>
						</button>
					</div>
				))}

				<div className="addPlaceForm__picturePreviews">
					{/* Afficher les images existantes */}
					{existingPictures.map((url, index) => (
						<div
							key={`existing-${index}`}
							className="picturePreview"
						>
							<img src={url} alt="Prévisualisation" />

							{userStatus === 'admin' && (
								<button
									type="button"
									className="picturePreview__remove"
									onClick={() =>
										handleRemoveExistingPicture(index)
									}
								>
									<span className="material-symbols-outlined">
										cancel
									</span>
								</button>
							)}
						</div>
					))}
				</div>
			</div>

			{error && <p className="error">{error}</p>}
			{successMessage && <p className="success">{successMessage}</p>}

			<div className="addPlaceForm__commande">
				<button
					className="addPlaceForm__button"
					type="submit"
					title="Valider"
				>
					{userStatus === 'admin'
						? 'Valider'
						: mode === 'update'
						? 'Proposer la mise à jour'
						: 'Proposer le lieu'}
				</button>

				{userStatus === 'admin' && (
					<button className="addPlaceForm__button" onClick={onClose}>
						Ne pas valider
					</button>
				)}
			</div>
		</form>
	)
}

AddPlaceForm.propTypes = {
	onClose: PropTypes.func.isRequired,
	initialLatitude: PropTypes.number,
	initialLongitude: PropTypes.number,
	userStatus: PropTypes.string
}

export default AddPlaceForm
