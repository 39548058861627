// import CitySearch from '../../Objects/CitySearch'

const MenuOpen = ({ title, children, variant, handleMenuClick }) => {
	return (
		<div className={`menuOpen ${variant}`}>
			<div className="menuOpen__head">
				{variant === 'menuOpen-nextAdventure' && (
					<div className="backElement">
						<button
							className="backElement__btn"
							onClick={() => handleMenuClick('menu1')}
							title="Retour"
						>
							<span className="material-symbols-outlined">
								arrow_back
							</span>
						</button>
					</div>
				)}

				<h2>{title}</h2>
			</div>
			<div className={`menuOpen__content ${variant}`}>{children}</div>
		</div>
	)
}

export default MenuOpen
