import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../../../services/firebaseConfig'
import PropTypes from 'prop-types'
import { useState } from 'react'

const CreateAccountButton = ({ className }) => {
	const [error, setError] = useState('')
	const navigate = useNavigate()

	// Fonction pour déconnecter l'utilisateur et le rediriger vers la page de création de compte
	const handleCreateAccount = () => {
		signOut(auth)
			.then(() => {
				navigate('/auth-choice') // Redirection vers la page de création de compte
			})
			.catch((err) => {
				setError('Erreur lors de la déconnexion.')
			})
	}

	return (
		<>
			<button
				className={`createAccount ${className}`}
				onClick={handleCreateAccount}
			>
				Se connecter
			</button>
			{error && <p className="message error">{error}</p>}
		</>
	)
}

CreateAccountButton.propTypes = {
	className: PropTypes.string // Classe CSS optionnelle pour le bouton
}

export default CreateAccountButton
